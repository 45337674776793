now = require("performance-now");
AppConsole = {
    initialized: false,

    init: function(logToConsole = true,debugLogToConsole = true, errorLogToConsole = true) {
        if (this.initialized) return;
        this.logToConsole = logToConsole // global switch to turn on/off console logging
        this.debugLogToConsole = debugLogToConsole
        this.errorLogToConsole = errorLogToConsole
        this.useTimeStamps = true;
        this.startTime = Math.round(now());
        this.timeSinceLast = 0;
        this.currentTime = Math.round(now());
    },
    log: function(...args) {
        if (!this.initialized) this.init();
        //console.log('logging to reactotron')
        this.timeSinceLast = Math.round(now()) - this.currentTime;
        this.currentTime = Math.round(now());
        if (this.logToConsole) console.log(this.currentTime,this.timeSinceLast,...args)
    },
    debugLog: function(...args) {
        if (!this.initialized) this.init();
        //console.log('logging to reactotron')
        if (this.debugLogToConsole) console.log(...args)
    },
    errorLog: function(...args) {
        if (!this.initialized) this.init();
        //console.log('logging to reactotron')
        if (this.errorLogToConsole) console.log(...args)
    },
    tron: {
        log: function(){return}
    }
}
