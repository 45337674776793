<template>
<div>
  <div class="container">
  <h1>SOUNDFUL SETTINGS</h1>
  <div @click="clickMethod" class="closeX"><font-awesome-icon icon="times" />X</div>
<!--  <div class="buttonContainerUpper">
    <router-link to="/joinform" class="typeformButton typeform-share button ">Sign Up for Trial</router-link>
  </div>
  -->
    <div v-on:click="logoutMethod" class="logoutButton">Dev Logout</div>

    <h3>Breaths / Min: {{breathsPerMinute}}</h3>
    <br>
    <div class="buttonGroup">
      <button v-on:click="breathsPerMinute--" class="appBtn">Down</button>
      <button v-on:click="breathsPerMinute++" class="appBtn">Up</button>
    </div>
    <br><br>
    <button v-on:click="saveButton" class="appBtn">Save</button>
</div>
</div>
</template>

<script>
import { firebaseApp } from '../../firebase.js';

export default {
name: "AppSettings",
  data() {
    return {
      firstName: '',
      breathsPerMinute: 8,
    }
  },
  methods: {
    nameChange: function (event) {
    },
    saveButton: function (event) {
      Physio.breathsPerMinute = this.breathsPerMinute;
      console.log('BPM: ',this.breathsPerMinute);
      AppFirebase.dbAddToUser({breathsPerMinute:this.breathsPerMinute});
      this.$router.go(-1);
    },
    clickMethod() {
      console.log('clicked');
      this.$router.go(-1);
      //add code that you wish to happen on click
    },
    logoutMethod() {
      console.log('clicked logout');
      firebaseApp.auth().signOut().then(function() {
        console.log('Successful logout')
        // Sign-out successful.
      }).catch(function(error) {
        console.log('Firebase logout error:',error);

        // An error happened.
      });
      firebaseApp
      //add code that you wish to happen on click
    }
  },
}
</script>

<style scoped>
h1 {
  text-align: center;
  color: whitesmoke;
  width: 100%;
  font-size: 1.2em;
  padding: 0 !important;
  margin: 0 !important;
}
p {
  color:white;
  text-align: center;
  opacity: 1;
  text-transform: uppercase;
}
.container {
  color: white;
  width: 33vw;
  height: 33vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  transform: scale(3) translate(0, 33%);
}
.buttonGroup {
  display: flex;
  justify-content: center;
  width: 100%;
}

.appBtn {
  text-decoration: none;
  color: #fff;
  background-color: #4285f4;
  text-align: center;
  letter-spacing: .5px;
  width: 30%;
  padding: 5%;
  margin: 1%;
  border: none;
}
.closeX {
  position: absolute;
  top: 0%;
  right: 1%;
  font-size: 1em;
  color:white;
}
.logoutButton {
  position:absolute;
  color: lightgrey;
  bottom:5%;
  right:10%;
}
</style>