import 'regenerator-runtime/runtime'
import Vue from 'vue'

import 'firebase/firestore';
import 'firebase/auth';

import Router from './router'; // Router being imported
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { faComments } from '@fortawesome/free-solid-svg-icons'
import { faFrown,faSmile,faMeh,faMusic } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
import VueFormulate from '@braid/vue-formulate'
import VueToggles from 'vue-toggles';
var get = require('lodash/get');
import Clipboard from 'v-clipboard'

Vue.component('v-icon', Icon)
Vue.use(VueFormulate)
Vue.component('VueToggles', VueToggles);
Vue.use(Clipboard)

require('./features/AppFirebase/AppFirebase')
require('./features/AppUtils/AppUtils')
require('./features/AppData/AppData')
window.AppData = AppData;

library.add(faUserSecret)
library.add(faWindowClose)
library.add(faTimes)
library.add(faComments)
library.add(faBell)
library.add(faSmile)
library.add(faFrown)
library.add(faMeh,faMusic)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false
Vue.config.silent = true; // turn this back off in future!

window.AppUser = {};
window.VueApp = new Vue({
    data() {
        return {
            shouldReportEvent:true,
            inEvent:false,
            prepost:'pre',  // before or after session
        };
    },    router:Router,
    async created() {
        window.router = this.$router;

        AppFirebase.init();
        AppFirebase.watchForAuthStateChange(this.$router);

    },
}).$mount('#app');

