<template name="Bellful">
  <div class="template">
    <div class="header">
      <h1>Bellful</h1>
      <h5>v0.06</h5>
    </div>
    <div id="bellfulVue" class="bellful"><p>
      <br>
      <h3>You Focused?</h3>
      <div class="focusButtons">
        <button v-on:click="Bellful.receiveResponse(100,$event)"><v-icon scale="3" name="thumbs-up"></v-icon> YES</button>
      <button v-on:click="Bellful.receiveResponse(50,$event)"><v-icon scale="3" name="hand-spock"></v-icon> SORTA</button>
      <button v-on:click="Bellful.receiveResponse(0,$event)"><v-icon scale="3" name="thumbs-down"></v-icon> NO</button><br>
      </div>
      <div v-if="false">
      <button v-on:click="Bellful.receiveResponse(100,$event)">100</button>
      <button v-on:click="Bellful.receiveResponse(90,$event)">90</button>
      <button v-on:click="Bellful.receiveResponse(80,$event)">80</button>
      <button v-on:click="Bellful.receiveResponse(70,$event)">70</button>
      <br>
      <button v-on:click="Bellful.receiveResponse(60,$event)">60</button>
      <button v-on:click="Bellful.receiveResponse(50,$event)">50</button>
      <br>
      <button v-on:click="Bellful.receiveResponse(0,$event)">Low</button>
      </div>
      <br>
      <br>
      <h3>Focused On</h3>
      <input v-model="focusObject" v-on:change="changeFocusObject" class="focusInput" placeholder="">
<!--      <p>Session Results: {{ sessionResults }}</p>-->
      <!--      <input  id="focus">-->
      <!--      <input class="focusInput" id="reminderFrequency" placeholder="Reminder Mins">-->
      <br><br>
      <button v-on:click="console.log('logged:',Bellful);Bellful.pause($event,false)"><v-icon scale="2.5" name="volume-mute"></v-icon> Pause</button>
      <button v-on:click="console.log('logged:',Bellful);Bellful.pause($event,true)"><v-icon scale="2.2" name="music"></v-icon> Pause</button>
      <button v-on:click="Bellful.noReminders"><v-icon scale="2.5" name="ban"></v-icon> Reminders</button>
      <br><br>

      <h4>
    <span v-bind:title="message">
    {{ message }}<br>
        {{ pausedMessage }}<br>
<!--        {{results}}-->
        {{ resultsMessage }}<br>
<!--        <h2>{{ encouragementMsg }}</h2>-->
  </span>
      </h4>
      <!--    <button onclick="Bellful.start()">Start</button><br>-->
      <br>
      <h3>Bell Every</h3>
      <button v-on:click="Bellful.startBells(1,$event)">1min</button>
      <button v-on:click="Bellful.startBells(5,$event)">5min</button>
      <button v-on:click="Bellful.startBells(10,$event)">10min</button>
      <button v-on:click="Bellful.startBells(30,$event)">30min</button>
      <br><br>
      <h3>Background Sound</h3>
      <button v-on:click="Bellful.chanting($event)">Chant</button>
      <button v-on:click="Bellful.water($event)">Water</button>
      <br>
      <button v-on:click="Bellful.breathing($event)">Breathing</button>
      <button v-on:click="Bellful.monks($event)">Monks</button>
      <button v-on:click="Bellful.restful($event)">Restful</button><br>
      <button v-on:click="copyToClipboard">
        Copy session results to clipboard
      </button>

    </div>
  </div>
</template>
<script>
const csv = require('csv-tools');
require('../AppAudio/AppAudio');
window.Bellful = {
  initialized: false,
  focusObject:'',
  sessionResults:'',
  playing: {
    chant: false,
    breathing: false,
    monks: false,
    restful: false,
    water: false,
  },
  paused: false,
  msgs: [],
  reminderPointers: [], // array of pointers to reminder intervals
  reminderGivenTime: null,
  reminderReceivedTime: null,
  reminderCount: 0,
  mins:0,
  bellsTransportRepeat:null,
  reminders:true, // whether to force user to enter a response
  startTime:0,
  lastReminderTime:0,
  focusTime:0,
  focusTimeTotal: 0,
  notfocusTimeTotal: 0,
  focusTimePercent: 0,
  results:{
    reminderCount:0,
    total:{
      focusTime:0,
      notfocusTime:0,
      allTime:0,
      pauseTime:0,
    }
  },

  init() {
    console.log('started');
    AppAudio.init();
    Bellful.msgs[0] = 'Oh Fuck!'
    Bellful.msgs[1] = 'Fuck Yeah!'
    Bellful.msgs[2] = 'HFS!!!'
    Bellful.msgs[3] = 'Are you kidding me?!?'
    Bellful.msgs[4] = '💩'
    Bellful.msgs[5] = '🇵🇷'
    Bellful.msgs[6] = 'God is dead'
    Bellful.msgs[7] = 'Onward!'
  },
  startBells(mins,event) {
    Bellful.results.startTime = Tone.now();
    Bellful.results.lastReminderTime = Tone.now();
    // already started previously
    if (Bellful.mins === mins) {
      Tone.Transport.clear(Bellful.bellsTransportRepeat);
      toggleHighlight(event);
      Bellful.mins = 0;
      return;
    }
    AppAudio.play('loudBell');
    //console.log('Hi');
    Bellful.bellsTransportRepeat = Tone.Transport.scheduleRepeat(() => {
      Bellful.startReminder();
    }, mins * 60);
    Tone.Transport.start();
    Bellful.mins = mins;
    toggleHighlight(event);
  },
  encouragement() {
    let msgNum = Math.round(Math.random() * 7);
    let msg = Bellful.msgs[msgNum];
    console.log('Encouragement: ',msg);
    this.component.pausedMessage = msg;
    //console.log(Bellful.msgs[msgNum]);
  },
  pause(event,reminders) {
    //console.log('Paused:',event);
    toggleHighlight(event);
    Bellful.paused = !(Bellful.paused);
    if (Bellful.paused) {
      Bellful.pauseStartTime = Tone.now();
      Bellful.component.pausedMessage = 'PAUSED';
      if (reminders) {
        this.pauseRemindersInterval = setInterval(()=>{
          AppAudio.drip(); // play reminder sound that we're in a pause
        },Bellful.mins * 60 * 1000 )
      }
      Tone.Transport.pause();
      AppAudio.pauseAll();

      // prepare to record pause time after
      Bellful.getResults(100);  // Bonus time! record pre-pause time since last reminder as focused
      Bellful.results.reminderGivenTime = Tone.now();
      Bellful.prepauseobject = Bellful.component.focusObject;
      Bellful.component.focusObject = 'Paused';
    } else {
      AppAudio.resumeAll();
      Bellful.component.pausedMessage = '~';
      Tone.Transport.start();
      Bellful.results.total.pauseTime+= Tone.now() - Bellful.pauseStartTime;
      clearInterval(this.pauseRemindersInterval);

      Bellful.getResults(100);  // record pause time
      Bellful.component.focusObject = Bellful.prepauseobject;
    }
  },
  noReminders(event) {
    //console.log('Paused:',event);
    toggleHighlight(event);
    Bellful.reminders = !(Bellful.reminders);
  },

  startReminder() {
    Bellful.results.reminderGivenTime = Tone.now();
    Bellful.results.reminderCount++;
    AppAudio.play('loudBell');

    //Bellful.encouragement();
    Bellful.clearPointers();
    if (!Bellful.reminders) return;
    Bellful.reminderPointers[1] = Tone.Transport.schedule(() => {
      AppAudio.play('loudBellDouble')
    }, "+15")
    Bellful.reminderPointers[2] = Tone.Transport.schedule(() => {
      AppAudio.play('loudBellTriple')
    }, "+30")
    Bellful.reminderPointers[3] = Tone.Transport.schedule(() => {
      Bellful.reminderMissed()
    }, "+50");
  },
  receiveResponse(focusNum,event) {
    Bellful.clearPointers();
    Bellful.encouragement();
    Bellful.getResults(focusNum);
    toggleHighlight(event);
    setTimeout(()=>{toggleHighlight(event)},2000);
  },
  getResults(focusNum) {
    Bellful.results.reminderReceivedTime = Tone.now();
    Bellful.results.reactionTime = Bellful.results.reminderReceivedTime - Bellful.results.reminderGivenTime;
    if ((Bellful.results.reactionTime < 60) && (focusNum > 0))
      Bellful.results.hitmiss = 'Hit'
    else {
      Bellful.results.hitmiss = 'Miss'
    }
    Bellful.results.timestampString = Bellful.getTimestampString();
    Bellful.results.current = {};
    Bellful.results.current.focusNum = focusNum;
    Bellful.results.trialTime = Tone.now() - Bellful.results.reminderGivenTime;
    Bellful.results.current.focusTime = Bellful.results.trialTime * focusNum/100;
    Bellful.results.total.focusTime +=  Bellful.results.trialTime * focusNum/100;

    Bellful.results.current.notfocusTime = Bellful.results.trialTime * (1-(focusNum/100));
    Bellful.results.total.notfocusTime +=  Bellful.results.trialTime * (1-(focusNum/100));

    Bellful.results.total.allTime += Bellful.results.trialTime;
    Bellful.results.focusTimeFraction = Bellful.results.total.focusTime / Bellful.results.total.allTime;
    this.component.results = Bellful.results;
    let msg = appendNumString(Bellful.results.total.focusTime/60,1);
    msg+= ' /';
    msg+= appendNumString(Bellful.results.total.allTime/60,1);
    msg+= ' min';
    msg+= appendNumString(Bellful.results.total.pauseTime/60,1);
    msg+= ' paused';
    msg+= appendNumString(Bellful.results.focusTimeFraction*100,0);
    msg+='%';
    this.component.resultsMessage = msg;
    let resultText = Bellful.reminderCount + ' '  + enquote(Bellful.component.focusObject) + ' ' + enquote(Bellful.results.hitmiss) + ' ' + focusNum + msg + ' ' + Bellful.getTimestampString()+ '\n';
    Bellful.component.sessionResults+= resultText;
    //console.log(Bellful.component.sessionResults);
  },
  logResult(num) {
    return;
    Bellful.reminderReceivedTime = Tone.now();
    Bellful.responseTime = Math.round(Bellful.reminderReceivedTime - Bellful.reminderGivenTime);
    let hitmiss = '';
    if (Bellful.responseTime < 60)
      hitmiss = 'Hit'
    else
      hitmiss = 'Miss'
    let msg = '';
    Bellful.focusTime = (num/100) * (Tone.now() - Bellful.lastReminderTime);
    Bellful.lastReminderTime = Tone.now();
    Bellful.focusTime = round(Bellful.focusTime,2);
    Bellful.focusTimeTotal = Bellful.focusTimeTotal + Bellful.focusTime;
    Bellful.totalTime = (Tone.now() - Bellful.startTime);
    Bellful.notfocusTimeTotal = round(Bellful.totalTime - Bellful.focusTimeTotal,2);
    Bellful.focusTimePercent = round((100 * Bellful.focusTimeTotal / Bellful.totalTime),2);

    msg+= appendNumString(msg, Bellful.reminderCount,0);
    msg+= ' "' + hitmiss + '"';
    msg+= appendNumString(msg, Bellful.responseTime);
    msg+= ' ' + timestamp + ' "'+ Bellful.component.focusObject + '"';
    msg+= appendNumString(msg,Bellful.focusTime/60,2);
    msg+= appendNumString(msg,Bellful.focusTimeTotal/60,2);
    msg+= appendNumString(msg,Bellful.notfocusTimeTotal/60,2);
    msg+= appendNumString(msg,Bellful.totalTime/60,2);
    msg+= appendNumString(msg,Bellful.focusTimePercent,0);
    msg+= '\n';
    //console.log(Bellful.reminderCount, num, hitmiss, Bellful.responseTime, dt, Bellful.component.focusObject);
    console.log(msg);
    //console.log('TotalTime:',Bellful.totalTime,' focusTime:',Bellful.focusTime,' focusTimeTotal:',Bellful.focusTimeTotal,' notfocusTimeTotal:',Bellful.notfocusTimeTotal);
    Bellful.component.sessionResults+= msg;
    this.component.resultsMessage = appendNumString(msg,Bellful.focusTimeTotal/60,0);
    this.component.resultsMessage+= ' /';
    this.component.resultsMessage+= appendNumString(msg,Bellful.totalTime/60,0);
    this.component.resultsMessage+= ' min ';
    this.component.resultsMessage+= appendNumString(msg,Bellful.focusTimePercent,0);
    this.component.resultsMessage+= '%';
  },
/*
  csv(test) {
    var jsonArray = [
      {
        "first_name": "Dennis",
        "last_name": "Anderson",
        "gender": "Male"
      }
    ];

    var csvData = csv.fromJSON(JSON.parse(JSON.stringify(Bellful.results)));

    console.log(csvData);
    return;
    test = ['testdata'];
    console.log('hi')
    let result = csv.fromJSON(test);
    console.log('CSV output:',result);
  },
  cdc2() {
    let foo = {test:'test'};
    for (var key in foo)
    {
      if (!foo.hasOwnProperty(key))
        continue;       // skip this property
      if (key == "child")
          console.log(key,foo[key]);
          }
  },
  cdc(objects) {
    let str = '';
    for(var key in objects) {
      var value = objects[key];
      str+= ' ' + value;
    }
    console.log(str);
  },
  downloadCSV (arr) {

/!*
    let arr = [{"am":{"a3":2,"a1":5,"a2":2},"zul":{"mer":1,"spi":1,"aut":1,"inf2":1,"int":1,"infl":1,"les":1},"kaab":      {"ka11":6,"ka12":6,"ka10":6},"kg3":"fdsf","ges":1,"alt":3,"kawe":{"ka9":4,"ka7":5,"ka8":5},"kaak":{"ka2":4,"ka3":5,"ka4":5,"ka1":4,"ka5":3,"ka6":5},"kg4":2,"kg1":5,"eink":"","kg2":4,"soz2":"","solz":"2","ae":{"a6":2,"a5":2,"a4":6}},
      {"am":{"a3":2,"a1":5,"a2":2},"zul":{"mer":1,"spi":1,"aut":1,"inf2":1,"int":1,"infl":1,"les":1},"kaab":      {"ka11":6,"ka12":6,"ka10":6},"kg3":"fdsf","ges":1,"alt":3,"kawe":{"ka9":4,"ka7":5,"ka8":5},"kaak":{"ka2":4,"ka3":5,"ka4":5,"ka1":4,"ka5":3,"ka6":5},"kg4":2,"kg1":5,"eink":"","kg2":4,"soz2":"","solz":"2","ae":{"a6":2,"a5":2,"a4":6}}
    ];
*!/

    var keys = [];
    var values = [];
    function getKeys(data, k = '') {
      for (var i in data) {
        var rest = k.length ? '_' + i : i
        if (typeof data[i] == 'object') {
          if (!Array.isArray(data[i])) {
            getKeys(data[i], k + rest)
          }
        } else keys.push( k+ rest)
      }
    }
    function getValues(data, k = '') {
      for (var i in data) {
        var rest = k.length ? '' + i : i
        if (typeof data[i] == 'object') {
          if (!Array.isArray(data[i])) {
            getValues(data[i], k + rest)
          }
        }
        else values.push(data[rest])
      }
    }

    getKeys(arr[0])
    var value="";
    arr.forEach(x=>{
      values=[];
      getValues(x);
      value+=values.join(";")+"\r\n";
    })

    let result = keys.join(";")+"\r\n"+value;
    return result;
  },
  convertToCSV(arr) {
//    const array = [Object.keys(arr[0])].concat(arr)

    return array.map(it => {
      return Object.values(it).toString()
    }).join('\n')
  },
  objToCSV(data) {
  const headers = Object.keys(data[0]).join();
  const content = data.map(r => Object.values(r).join());
  return [headers].concat(content).join("\n");
},
  objectsToCSV(arr) {
  const array = [Object.keys(arr[0])].concat(arr)
  return array.map(row => {
    return Object.values(row).map(value => {
      return typeof value === 'string' ? JSON.stringify(value) : value
    }).toString()
  }).join('\n')
},
  json2csv(obj) {
    const items = JSON.obj;
    const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
    const header = Object.keys(items[0])
    const csv = [
      header.join(','), // header row first
      ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    ].join('\r\n')

    console.log(csv)
  },

*/
  getTimestampString() {
    let dt = new Date();
    let timestamp = enquote(dt.toISOString().slice(0, 19).replace(/-/g, "/").replace("T", " ")); // eg "2021/01/04 13:52:43"
    timestamp+= " " + enquote(dt.toString().match(/([A-Z]+[\+-][0-9]+)/)[1]); // eg "GMT-0400"
    return timestamp
  },
  reminderMissed() {
    Bellful.getResults(0);
    AppAudio.pop();
//    Bellful.logResult(0);
  },
  clearPointers() {
    Tone.Transport.clear(Bellful.reminderPointers[1]);
    Tone.Transport.clear(Bellful.reminderPointers[2]);
    Tone.Transport.clear(Bellful.reminderPointers[3]);
  },
  chanting(event) {
    if (Bellful.playing.chant) {
      AppAudio.pause('chant');
      Bellful.playing.chant = !Bellful.playing.chant
    } else {
      AppAudio.loop('chant');
      Bellful.playing.chant = !Bellful.playing.chant
    }
    toggleHighlight(event);
  },
  water(event) {
    if (Bellful.playing.water) {
      AppAudio.pause('background');
      Bellful.playing.water = !Bellful.playing.water
    } else {
      AppAudio.loop('background');
      Bellful.playing.water = !Bellful.playing.water
    }
    toggleHighlight(event);
  },
  breathing(event) {
    if (Bellful.playing.breathing) {
      AppAudio.pause('breathing');
      Bellful.playing.breathing = !Bellful.playing.breathing
    } else {
      AppAudio.loop('breathing');
      Bellful.playing.breathing = !Bellful.playing.breathing
    }
    toggleHighlight(event);
  },
  monks(event) {
    if (Bellful.playing.monks) {
      AppAudio.pause('monks');
      Bellful.playing.monks = !Bellful.playing.monks
    } else {
      AppAudio.loop('monks');
      Bellful.playing.monks = !Bellful.playing.monks
    }
    toggleHighlight(event);
  },
  restful(event) {
    AppAudio.play('loudBell');
    if (Bellful.playing.restful) {
      AppAudio.pause('restful');
      Bellful.playing.restful = !Bellful.playing.restful
    } else {
      AppAudio.loop('restful');
      Bellful.playing.restful = !Bellful.playing.restful
    }
    toggleHighlight(event);
  }
}
function appendNumString(num,dec) {
  return ' ' + num.toFixed(dec);
}
function round(num, dec) {
  const [sv, ev] = num.toString().split('e');
  return Number(Number(Math.round(parseFloat(sv + 'e' + dec)) + 'e-' + dec) + 'e' + (ev || 0));
}
export default {
  name: 'Bellful',
  data() {
    return {
      message: '00:00',
      pausedMessage: '~',
      resultsMessage:'',
      encouragementMsg: '.',
      focusObject: '',
      sessionResults:'',
      results:{}
    }
  },
  computed:{
    console:()=>console,
    test:()=>Bellful.pause,
    Bellful:()=>Bellful,
  },
  mounted() {
    Bellful.component = this;
    Bellful.init();
    setInterval(()=>this.updateTime(),5000);
  },
  methods: {
    changeFocusObject: function (event) {
      console.log('focus Object:', this.focusObject);
      Bellful.focusObject = this.focusObject;
    },
    copyToClipboard: function(){
      this.$clipboard(this.sessionResults)
    },
    updateTime: function() {
        let nw = 1000*AppAudio.now()*timeRatio;
        let hrs = Math.floor(nw/(60*60*1000)%60).toString().lpad("0",2);
        let mins = Math.floor(nw/(60*1000)%60).toString().lpad("0",2);
        let secs = Math.floor(nw/(1000)%60).toString().lpad("0",2);
        if (hrs < 1)
          this.message = mins + ':' + secs;
        else
          this.message = hrs + ':' + mins + ':' + secs;
    }
  }
}
String.prototype.lpad = function (padString, length) {
  var str = this;
  while (str.length < length)
    str = padString + str;
  return str;
}
let timeRatio = 1;

function toggleHighlight(event) {
  let highlightClass = 'highlightElement';
  let alreadyHighlighted = event.target.classList.contains(highlightClass);
  if (!alreadyHighlighted) {
    event.target.classList.add(highlightClass);
  }
  else {
    event.target.classList.remove(highlightClass);
  }
}
function enquote(str) {
  return '"' + str + '"';
}
//Bellful.start();
</script>
<style lang="scss" scoped>
* {
  font-family: Arial;
  color:lightgrey;
}
.template {
  background-color: #1a237e;
  position:absolute;
  height:100%;
  width:100%;
  opacity: 1;
}
.header {
  position:absolute;
  top:0;
  height:8%;
  width:100%;
  background-color: #3700B3;
  color:white;
}
h1, h2, h3 {
  opacity: 0.9;
  position: relative;
}

h1 {
  font-size: 3em;
  text-align: left;
  margin-top:4%;
  margin-left:5%;
  color:whitesmoke;
}
h5 {
  font-size: 1.5em;
  text-align: left;
  margin-bottom:4%;
  margin-left:5%;
}
h2 {
  font-size: .5em;
}

h2 {
  font-size: .5em;
}
h3 {
  font-size: .7em;
  text-transform: uppercase;
  padding-bottom: 2%;
}
button {
  border: none;
  border-radius: 0.5em;
  font-size: .7em;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.35);
  padding: 0.6em;
  margin: 0.2em;
  min-width: 22%;
  text-transform: uppercase;
}

input {
  background-color: rgba(256, 256, 256, 0.15);
  font-size: 1em;
  border: 0;
  padding: 10px;
  margin: 1%;
  width: 90%;
  margin-right: .5%;
  text-align: center;
  border-radius: .3em;
}

.bellful {
  position:absolute;
  top:8%;
  height:92%;
  width: 100%;
  background-color: transparent;
  text-align: center;
  height: auto;
  font-size: 3em;
  color: white;
}

.focusInput {
  text-align: left;
  width: 80%;
  background-color: rgba(256, 256, 256, 0.5);
}
.highlightElement {
  background-color: #6200ee;
  /*background-color: rgb(85, 147, 255);*/
}
.focusButtons {
  display:flex;
  flex-direction: row;
  justify-content: space-around;
}
</style>
