const { firebaseui, firebaseApp,FieldValue,Timestamp } = require('../../firebase');
const firebase = firebaseApp;
//const admin = require('firebase-admin'); // only user server-side
require( "../AppData/AppReadableId");
require('../../../private/AppInfo.js');
require('../AppData/AppData');
const get = require('lodash/get')
let shimValue = 'xxxx'
AppFirebase =
    {
        logit: false,
        initialized:false,
        defeatRequireAuth:false, // use this for testing only, to avoid requiring auth

        // This is the initialization that happens at app outset, potentially before we have any appUser info
        async init()
        {
            if (AppFirebase.logit) console.log('Initializeing AppFirebase');
            if (this.initialized) return;
            let result = await this.initFirestoreDatabase();
//            this.appUser = await this.getAppUser(); // Do NOT include this here!
            this.userOS = shimValue;
            this.initialized = true;
            if (AppFirebase.logit) console.log('AppFirebase Initialized');
        },
        async initFirestoreDatabase()
        {
            if (this.initialized) return this.initialized; // we return the promise that was previously created to fetch the appUser
            //console.log('FIREBASE = ',firebase);
            try {
                this.initialized = true;
                firebase.firestore()
                    .enablePersistence()
                    .catch(function (err) {
                        if (err.code == 'failed-precondition') {
                            console.log('CdC Firebase Error failed-precondition')
                            // Multiple tabs open, persistence can only be enabled
                            // in one tab at a a time.
                            // ...
                        } else if (err.code == 'unimplemented') {
                            console.log('CdC Firebase Error unimplemented')
                            // The current browser does not support all of the
                            // features required to enable persistence
                            // ...
                        }
                    });
                // Subsequent queries will use persistence, if it was enabled successfully

                this.firebasedb = firebase.firestore();

                if (this.logit) console.log('Firebase initialized:', this.firebasedb);

                this.initialized = new Promise((resolve) => {
                    let called = false;
                    firebase.auth().onAuthStateChanged((user) => {
                        if (user && user.email === 'dev@g.com') // dev mode appUser
                        {
                            console.log('AppFirebase Auth State Changed.');
                            AppInfo.firebasePrefix = 'dev-';
                        }
                        if (!called) {
                            called = true
                            resolve(user)
                        }
                    })
                })

                return this.initialized
            } catch (error) {
                console.log('CDC ERROR ***: Firebase initialize error:', error);
                console.log();
            }
        },
        watchForAuthStateChange(router){
            if (AppFirebase.defeatRequireAuth) return;
            // listen for for auth state.  If needed: force login, load user from db, or create user from db
            firebaseApp.auth().onAuthStateChanged(async (user) => {
                AppFirebase.handleUserFromAuth(user);
            });
        },
        async testLogin()
            // this is the critical unit test that basically everything is working with auth and reading user from db
        {
            let firebaseAuthUser = await AppFirebase.getFirebaseAuthUser();
            AppFirebase.handleUserFromAuth(firebaseAuthUser);
        },
        async handleUserFromAuth(firebaseAuthUser){
            if(firebaseAuthUser) {
                let providerData = firebaseAuthUser.providerData[0];
                let displayName = get(providerData,'displayName','');
                if (AppFirebase.logit) console.log('*** AUTHENTICATED !! ***.  providerUser:',providerData);

                // check if user is in database
                await AppFirebase.init();
                let appUser = await AppFirebase.getAppUser();
                let firstName = get(appUser,'firstName','');
                if ( (typeof appUser == 'undefined') || (firstName==='') || (displayName ==='')) {
                    if (AppFirebase.logit) console.log('+++++++ User not found in database:',appUser,'or blank name:',displayName,' creating new user');
                    AppFirebase.appUser = await AppFirebase.createNewUser(firebaseAuthUser);
                }
                else {
                    AppFirebase.appUser = appUser;
                    if (AppFirebase.logit) console.log('*** GOT USER FROM DATABASE: appUser from firestore: ',firstName,appUser);
                }
                //navigateTo(this.$router,'apppractice');
            } else {
                if (AppFirebase.logit) console.log('*** NEED TO AUTHENTICATE !! *** From Current Route:',router.currentRoute)
                //this.$router.push('/auth')
                AppFirebase.navigateTo(router,'applogin');
            }
        },
        navigateTo(router,routename) {
            let currentRoute = router.currentRoute.name;
            currentRoute = currentRoute.toLowerCase();
            currentRoute = currentRoute.replace('/','');
            routename = routename.toLowerCase();
            routename = routename.replace('/','');
            if (currentRoute !== routename) {
                router.push(routename);
            }
        },
        async getToken() {
            await AppFirebase.init();
            let firebaseAuthUser = await AppFirebase.getFirebaseAuthUser();
            firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
                console.log('idToken: ',idToken)
                // Send token to your backend via HTTPS
                // ...
            }).catch(function(error) {
                console.log('idToken didnt work');
                // Handle error
            });
        },
        getTimeUTC(){
            var now = new Date;
            var utc_timestamp = Date.UTC(now.getUTCFullYear(),now.getUTCMonth(), now.getUTCDate() ,
                now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
            return utc_timestamp;
        },
        userLocation() {
            // reverse lookup service: https://api.ip2location.com/v2/?ip=142.113.220.31&addon=continent&lang=zh-cn&key=demo
            // const status = document.querySelector('#status');
            const mapLink = document.querySelector('#map-link');


            mapLink.href = '';
            mapLink.textContent = '';

            function success(position) {
                const latitude  = position.coords.latitude;
                const longitude = position.coords.longitude;

                status.textContent = '';
                mapLink.href = `https://www.openstreetmap.org/#map=18/${latitude}/${longitude}`;
                mapLink.textContent = `Latitude: ${latitude} °, Longitude: ${longitude} °`;
            }

            function error() {
                status.textContent = 'Unable to retrieve your location';
            }

            if(!navigator.geolocation) {
                status.textContent = 'Geolocation is not supported by your browser';
            } else {
                status.textContent = 'Locating…';
                navigator.geolocation.getCurrentPosition(success, error);
            }
           /* let geo = new GeoPoint.toString();
            return geo;*/

        },
        serverTimestamp() {
            // Notes on file timestamps
            // Timestamps are in UTC (GMT) time in numeric seconds format
            // In firestore console the data is displayed in the local timezone of the viewing machine
            // In Zapier the sessions are marked as createdAt based on time in PST / California
            // firestore only allows createdAt fields to be written in this special format.
            // normally, the value is gotten directly from the server using firebase.firestore.FieldValue.serverTimestamp()
            // that approach requires firestore-admin, which shouldn't be used on the client. Therefore, this kludge is used for now
            let ts = Timestamp.now(); // UTC timestamp
            return ts;
        },
        getProviderData(firebaseAuthUser) {
            let providerData = firebaseAuthUser.providerData[0];
            return providerData;
        },
        async getUid() {
            if (arguments.length<1)
                firebaseAuthUser = await AppFirebase.getFirebaseAuthUser();

            if (firebaseAuthUser===null) {
                if (AppFirebase.logit) console.log('getAppUser: no authenticated firebase user from getFirebaseAuthUser')
                return null;
            }
            let providerData = AppFirebase.getProviderData(firebaseAuthUser);
            let providerUid = get(providerData,'uid',null); // DONT USE THIS
            let uid = get(providerData,'uid',null); // Use the user-specific uid, not the provider-specific Uid
            return uid
        },
        async createNewUser(firebaseAuthUser)
        {
            // Create a new user in the database linked to the already authed user from firebase auth
            if (arguments.length<1) {
                firebaseAuthUser = await AppFirebase.getFirebaseAuthUser();
            }
            let providerData = firebaseAuthUser.providerData[0];
            if (AppFirebase.logit) console.log('createNewUser Provider Data:',providerData);
            AppFirebase.appUser = {};
            let uid = await AppFirebase.getUid(); // get the current authenticated user uid
            AppFirebase.appUser.uid = uid;
            if (providerData != null) {
                let displayName = get(providerData,'displayName','');
                AppFirebase.appUser.displayName = displayName;
                AppFirebase.appUser.firstName = displayName.split(' ').slice(0, -1).join(' ');
                AppFirebase.appUser.lastName = displayName.split(' ').slice(-1).join(' ');
                AppFirebase.appUser.email = get(providerData,'email','');
                AppFirebase.appUser.photoUrl = get(providerData,'photoURL','');
                AppFirebase.appUser.emailVerified = get(providerData,'emailVerified',false);
                AppFirebase.appUser.providerId = get(providerData,'providerId','');
                AppFirebase.appUser.providerUid = get(providerData,'uid','');  // The user's ID, unique to the Firebase project. Do NOT use
                // this value to authenticate with your backend server, if
                // you have one. Use User.getToken() instead.
            }
            // CREATE NEW USER
            AppFirebase.appUser.phone = '1234567890';
            AppFirebase.appUser.breathsPerMinute = 8;
            AppFirebase.appUser.numSessions = 0;
            AppFirebase.appUser.durationTotal = 0;

            AppFirebase.appUser.userId = window.AppReadableId();
            AppFirebase.appUser.metaData = shimValue;
            if (AppFirebase.logit) console.log('Creating new user:',AppFirebase.appUser);
            await AppFirebase.dbCreateUser(AppFirebase.appUser) // Write to Firebase
//            AppFirebase.appUser = await AppFirebase.getAppUser(); // after creating new user, reload from database to correctly initialize
            if (AppFirebase.logit)console.log('CreateNewUser Got AppUser:',AppFirebase.appUser);
        },
        async getFirebaseAuthUser()
        {
            window.firebase = firebaseApp;
            //if (this.logit) console.log('Firebase = ',firebase);
            // TODO: does this need an await / need a non-async local version?
            const firebaseAuthUser = firebase.auth().currentUser;
            if (firebaseAuthUser === null) return;
            if (this.logit) console.log('getFirebaseAuthUser firebase.auth user:',firebaseAuthUser);
            return firebaseAuthUser;
        },
        async getAppUser()
        {
            let uid = await AppFirebase.getUid();
            if (AppFirebase.logit) console.log('Firebase Auth UID:',uid);
            // open users collection in firestore database, search for user based on auth user uid
            console.log('firebasedb:',AppFirebase.firebasedb);
            let collectionRef = AppFirebase.firebasedb.collection(AppInfo.firebasePrefix + 'users');
            if (AppFirebase.logit) console.log('Got collectionRef:',collectionRef,' uid:',uid);
            const userResponse = await collectionRef.where("uid", "==", uid).limit(1).get();
            if (AppFirebase.logit) console.log('uid:', firebaseAuthUser.uid, 'userResponse:', userResponse);
            if (!userResponse.empty) {
                this.appUser = userResponse.docs[0].data();
                if (AppFirebase.logit) console.log('From Firestore Database: ' + this.appUser.userId + ' ' + this.appUser.email);
                return this.appUser;
            } else {
                if (AppFirebase.logit) console.log('No entry found in Firebase database for uid: ', uid);
                return null;
            }
        },
        async updateMetaData()
        {
            user.metaData = shimValue;
            return;
            // Related to AppDatabase, Update
            let user = Appr.AppData.get('appUser');
            this.metaData = {
                build: AppInfo.build,
                app: 'Brainful',
                os: this.userOS,
                deviceName: shimValue,
                deviceId: shimValue,
                deviceYearClass: shimValue,
                releaseChannel: shimValue,
                userGroup: '', // eg beta
                userStatus: '', // eg paid subscription
            }
            if (user.userId) this.metaData.userId = user.userId;
            if (user.email) this.metaData.username = user.email;
            if (AppInfo.build) this.metaData.build = AppInfo.build;
            if (user.email === 'dev@g.com') // dev mode appUser
            {
                console.log('External Services Dev Mode');
                AppInfo.firebasePrefix = 'dev-';
            }

            this.logEvent('AppStart', {});
            //console.log('External Services Setting MetaData to:',this.metaData);
            if (this.userOS != 'web') {
                this.idUserSegment()
                this.idUserSentry()
                this.idUserAmplitude()
                this.idUserGA()
            }
        },
        async incrementField(field) {
            let docRef = this.firebasedb.collection(AppInfo.firebasePrefix + 'users');
            const userResponse = await docRef.where("uid", "==", uid).limit(1).get();
            userResponse.update(field,FieldValue.increment(1));
            return;
            user.updatedAt = AppFirebase.serverTimestamp();
            user.build = AppInfo.build;
            user.metaData = this.metaData;
            let setUser = docRef.doc(user.userId).set(user, {merge: true});
            console.log('dbUpdateUser: ', user);


            washingtonRef = db.collection("cities").document("DC");

// Atomically increment the population of the city by 50.
            washingtonRef.update("population", FieldValue.increment(50));
        },

        testError()
        {
            console.log('Testing External Services Error');
            Sentry.captureException(new Error('Oops!'))
        },

        logoutFirebase()
        {
            return firebase.auth().signOut()
        },
        dbAddToUserOld(toAdd) // pass an object with field(s) to add
        {
            if (typeof this.user.userId != null) {
                toAdd.updatedAt = AppFirebase.serverTimestamp();
                toAdd.userId = this.user.userId;
                let docRef = this.firebasedb.collection(AppInfo.firebasePrefix + 'users');
                docRef.doc(this.user.userId).set(toAdd, {merge: true});
                console.log('Updated dB: ', toAdd)
            }
        },
        async dbAddToUser(toAdd) // pass an object with field(s) to add
        {
            let collectionRef = AppFirebase.firebasedb.collection(AppInfo.firebasePrefix + 'users');
            toAdd.updatedAt = AppFirebase.serverTimestamp();
            toAdd.build = AppInfo.build;
            collectionRef.doc(AppFirebase.appUser.userId).set(toAdd, {merge: true});
            if (AppFirebase.logit) console.log('Updated dB: ', toAdd)
        },
        async dbUpdateUser(user)
        {
            let collectionRef = AppFirebase.firebasedb.collection(AppInfo.firebasePrefix + 'users');
            user.updatedAt = AppFirebase.serverTimestamp();
            user.build = AppInfo.build;
            collectionRef.doc(AppFirebase.appUser.userId).set(user, {merge: true});
            if (AppFirebase.logit) console.log('Updated dB: ', user);
        },
        dbReadUser(user)
        {
            let docRef = this.firebasedb.collection(AppInfo.firebasePrefix + 'users');
            user.updatedAt = AppFirebase.serverTimestamp();
            user.build = AppInfo.build;
            let setUser = docRef.doc(user.userId).set(user, {merge: true});
            console.log('dbUpdateUser: ', user);
        },
        async dbCreateUser(user)
        {
            //console.log('Creating User in Database:', user);
            this.userId = user.userId;
            let docRef = this.firebasedb.collection(AppInfo.firebasePrefix + 'users');
            let dt = new Date();
            user.createdAt = AppFirebase.serverTimestamp();
            user.iweekCohort = this.weekSinceInception();
            user.idayCohort = this.daysSinceInception();
            user.imonth = dt.getMonth() + 1;
            user.iday = dt.getDate();
            user.ihour = dt.getHours();
            user.os = shimValue;
            user.metaData = shimValue;
            user.terms = true;
            user.beatsPerMinute = 0;
            user.personalSound = {};
            // saving the appUser in the appUser db
            await docRef.doc(user.userId).set(user);
            console.log('Succesfully created user: ',user);
        },
        dayOfYear()
        {
            var now = new Date();
            var start = new Date(now.getFullYear(), 0, 0);
            var diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
            var oneDay = 1000 * 60 * 60 * 24;
            var day = Math.floor(diff / oneDay);
            return day;
        },
        daysSinceInception()
        {
            var now = new Date();
            var start = new Date("August 06, 2019 00:00:00");
            var diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
            var oneDay = 1000 * 60 * 60 * 24;
            var day = Math.floor(diff / oneDay);
            return day;
        },
        weekSinceInception()
        {
            let days = this.daysSinceInception();
            let weeks = Math.floor(days / 7);
            return weeks;
        },
        dbCreateDocument(collectionName,obj) {
            let docRef = this.firebasedb.collection(AppInfo.firebasePrefix + collectionName);
            let dt = new Date();
            obj.createdAt = AppFirebase.serverTimestamp();
            obj.appUser = this.appUser;
            if (this.logit) console.log('dbCreateDocument:',obj);
            docRef.doc(id).set(obj);
        },
        dbCreateEvent(obj)
        {
            let id = AppReadableId();
            let docRef = this.firebasedb.collection(AppInfo.firebasePrefix + 'events');
            obj.createdAt = AppFirebase.serverTimestamp();
            docRef.doc(id).set(obj);
        },
        dbCreateSession(session)
        {
            let id = get(session, 'id');
            session = {};
            let docRef = this.firebasedb.collection(AppInfo.firebasePrefix + 'sessions');
            let dt = new Date();
            session.createdAt = AppFirebase.serverTimestamp();
            session.imonth = dt.getMonth() + 1;
            session.iday = dt.getDate();
            session.ihour = dt.getHours();
            session.idayCohort = this.daysSinceInception();
            session.os = shimValue;
            session.build = AppInfo.build;
            session.id = shimValue;
            session.nextPractice = shimValue;
            session.userId = shimValue;
            session.sessionNum = shimValue;
            session.appUser = this.appUser;
            if (this.logit) console.log('dbCreateSession:',session);
            let setSession = docRef.doc(id).set(session);
        },
        dbUpdateSession(session)
        {
            // TechDebt 2: appSession createdAt is for some unclear reason being update during update
            let id = get(session, 'id');
            let docRef = this.firebasedb.collection(AppInfo.firebasePrefix + 'sessions');
            if (docRef === null) {
                this.dbCreateSession(session)
                console.log('** dbUpdateSession Creating appSession, there should already be one...');
            } else {
                let dt = new Date();
                session.updateAt = AppFirebase.serverTimestamp();
                let setSession = docRef.doc(id).set(session);
            }
            if (this.logit) console.log('dbUpdateSession')
        },

        dbAddTrace(trace)
        {
            let id = AppReadableId();
            let docRef = this.firebasedb.collection(AppInfo.firebasePrefix + 'traces');
            trace.createdAt = AppFirebase.serverTimestamp();
            trace.build = AppInfo.build;
            trace.email = this.email;
            let setUser = docRef.doc(id).set(trace);
        },
        dbAddSleepWake(SleepWake)
        {
            let id = AppReadableId();
            let docRef = this.firebasedb.collection(AppInfo.firebasePrefix + 'SleepWake');
            SleepWake.createdAt = AppFirebase.serverTimestamp();
            SleepWake.build = AppInfo.build;
            SleepWake.email = this.email;
            let setUser = docRef.doc(id).set(SleepWake);
        },
        // DEV ONLY !! This is only one appSession, for test saving/retrieving
        dbSaveSession()
        {
            return;
            let tempSession = Appr.AppData.getSessionInfo()
            Appr.ExternalServices.dbSaveData('temp-appSession', tempSession);
        },
        // DEV ONLY !! This is only one appSession, for test saving/retrieving
        async dbLoadSession()
        {
            return
            let tempSession = await Appr.ExternalServices.dbLoadData('temp-appSession');
            Appr.AppData.data.session = tempSession;
        },
        // Save arbitrary data collection, eg to restore an object later for dev/testing
        dbSaveData(name, data)
        {
            let id = AppReadableId();
            let docRef = this.firebasedb.collection(AppInfo.firebasePrefix + name);
            let doc = {};
            doc.createdAt = AppFirebase.serverTimestamp();
            doc.build = AppInfo.build;
            doc.data = data;
            docRef.doc(id).set(doc);
            if (this.logit) console.log('dbSaveData')
        },
        temp()
        {
            let query = citiesRef.where('capital', '==', true).get()
                .then(snapshot => {
                    if (snapshot.empty) {
                        console.log('No matching documents.');
                        return;
                    }

                    snapshot.forEach(doc => {
                        console.log(doc.id, '=>', doc.data());
                    });
                })
                .catch(err => {
                    console.log('Error getting documents', err);
                });
        },
        // Load arbitrary data collection, eg to restore an object later for dev/testing
        async dbLoadData(name)
        {
            let docRef = this.firebasedb.collection(AppInfo.firebasePrefix + name);
            return new Promise(function (resolve, reject) {
                let query = docRef.get()
                    .then(snapshot => {
                        if (snapshot.empty) {
                            //console.log('No matching documents.');
                            reject('AppFirestore No matching documents');
                            return;
                        }
                        let result = snapshot.docs[0].data().data;
                        if (result) {
                            resolve(result);
                        }
                        resolve(snapshot);
                    })
                    .catch(err => {
                        console.log('Error getting documents', err);
                        reject(err);
                    });
            });
        },
        dbTestWrite(userId, score)
        {
            if (arguments.length < 2) {
                userId = 'exampleId';
                score = Math.round(Math.random() * 100);
            }
            let docRef = this.firebasedb.collection(AppInfo.firebasePrefix + 'users1').doc('blovelace');
            let setAda = docRef.set({
                first: 'Ada',
                last: 'Lovelace',
                userId: userId,
                score: score
            }, {merge: true});
        },
        dbTempSave(data)
        {
            let docRef = this.firebasedb.collection(AppInfo.firebasePrefix + 'tempSaved');
        },
        dbTestRead()
        {
            let t = {
                "orderBy": [{
                    "field": {
                        "fieldPath": "createdAt"
                    },
                    "direction": "DESCENDING"
                }],
            }
            let docRef = this.firebasedb.collection(AppInfo.firebasePrefix + 'users1').doc('blovelace');
            let getDoc = docRef.get()
                .then(doc => {
                    if (!doc.exists) {
                        console.log('No such document!');
                    } else {
                        console.log('Document data:', doc.data());
                    }
                })
                .catch(err => {
                    console.log('Error getting document', err);
                });
        },
        // Returns a promise that resovles to the query result.  Use eg q = await this.dbTestQuery()
        async dbTestQuery()
        {
            let docRef = this.firebasedb.collection(AppInfo.firebasePrefix + 'sessions');
            return new Promise(function (resolve, reject) {
                let query = docRef.where('email', '==', 'Pixel2@g.com').get()
                    .then(snapshot => {
                        if (snapshot.empty) {
                            console.log('No matching documents.');
                            return;
                        }
                        let result = [];
                        let count = 0;
                        snapshot.forEach(doc => {
                            console.log(doc.id, '=>', doc.data());
                            result[count] = doc.data()
                            count = count + 1;
                        });
                        resolve(result);
                    })
                    .catch(err => {
                        console.log('Error getting documents', err);
                        reject(err);
                    });
            });
        },
        async dbStreakQuery(username)
        {
            let docRef = this.firebasedb.collection(AppInfo.firebasePrefix + 'sessions');
            return new Promise(function (resolve, reject) {
                let query = docRef.where('email', '==', username).get()
                    .then(snapshot => {
                        if (snapshot.empty) {
                            //console.log('No matching documents.');
                            resolve(null);
                            return;
                        }
                        let result = [];
                        let count = 0;
                        console.log('Snapshot:', snapshot);
                        snapshot.forEach(doc => {
                            //console.log(doc.id, '=>', doc.data());
                            result[count] = doc.data()
                            count = count + 1;
                        });
                        resolve(result);
                    })
                    .catch(err => {
                        console.log('Error getting documents', err);
                        reject(err);
                    });
            });
        },
        async dbLoadUser(username)
        {
            let docRef = this.firebasedb.collection(AppInfo.firebasePrefix + 'users');
            return new Promise(function (resolve, reject) {
                let query = docRef.where('email', '==', username).get()
                    .then(snapshot => {
                        if (snapshot.empty) {
                            //console.log('No matching documents.');
                            resolve(null);
                            return;
                        }
                        let result = [];
                        let count = 0;
                        snapshot.forEach(doc => {
                            //console.log(doc.id, '=>', doc.data());
                            result[count] = doc.data()
                            count = count + 1;
                        });
                        resolve(result);
                    })
                    .catch(err => {
                        console.log('Error getting documents', err);
                        reject(err);
                    });
            });
        },
    }