// AppLogin.vue

<template name="AppLogin">
  <div id="AppLogin" class="vueApp">
    <h1>SOUNDFUL</h1>
    <br>
    <div class="appInfo"><p>{{name}}</p></div>
    <div id="firebase" class="firebaseWrapper">
      <div class="authBox">
        <div class="loginText">Brainful <br><br><br><br></div>
<!--        <img class="authLogoImg" src='../../../public/assets/images/infinity-logo.png'></div>-->
        <div id="firebaseui-auth-container"></div>
        <div id="FirebaseUIloader">Loading...</div>
      </div>
    </div>
    <!--    <div id="g_id_onload"
             data-client_id="501438443061-goolj413r87007fqi628evc4tcm5th5j.apps.googleusercontent.com"
             data-login_uri=/bar"
             data-your_own_param_1_to_login="any_value"
             data-your_own_param_2_to_login="any_value">
        </div>-->
  </div>

</template>

<script >
import {firebaseApp,firebaseAuth,uiConfig,firebaseui} from '../../firebase';
import 'firebaseui'

export default ({
  data() {
    return {
      name: "Login",
    };
  },
  methods: {
  },
  created(){
    window.router = this.$router;
    console.log('Router:',window.router)
  },
  mounted() {
    console.log('AppLogin Mounted.')
    console.log('FirebaseAuth = ',firebaseAuth);
    console.log('FirebaseUI = ',firebaseui);
    var ui = new firebaseui.auth.AuthUI(firebaseAuth);
    ui.start('#firebaseui-auth-container', uiConfig);
  }
});
</script>
<style >
@import './firebaseui.css';
.authBox {
  transform: scale(3);
}
.loginText {
  color:white;
  text-align: center;
  text-transform: uppercase;
  opacity: .8;
}
.authLogoImg {
  position: absolute;
  width: 5em;
  height: auto;
  opacity: .8;
}
</style>
