<template name="Sample">
    <div>Sample Template
      <router-view></router-view>
      <router-link to="/joinform"> Join </router-link>
      <router-link to="/eventform"> Report Event </router-link>
    </div>
</template>

<script>
    export default {
        name: 'Sample',
        data () {
            return {
                msg: 'Welcome to Your Vue.js App',
                bundler:'Parcel'
            }
        },
      methods: {
      },
      mounted() {
        console.log('Bar Component Mounted')
      }
    }
</script>

<style  scoped>
    p {
        color: red;
    }
</style>