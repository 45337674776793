import Vue from 'vue'
import Router from 'vue-router'
import Sample from "./components/Examples/Sample";
import EventForm from "./features/AppTypeform/EventForm";
import AppSettings from "./features/AppSettings/AppSettings";
import AppGetUserInputs from "./features/AppGetUserInputs/AppGetUserInputs";
import JoinForm from "./features/AppTypeform/JoinForm";
import AppPractice from "./features/AppPractice/AppPractice";
import AppLogin from "./features/AppAuth/AppLogin";
import Auth from "./features/AppAuth/Auth";
import Bellful from "./features/Bellful/Bellful";
Vue.use(Router)

const routes = [
    { path: '/sample', name:'sample', component: Sample },
    { path: '/appsettings', name:'AppSettings', component: AppSettings },
    { path: '/applogin', name:'AppLogin', component: AppLogin },
    { path: '/joinform', name:'JoinForm', component: JoinForm },
    { path: '/eventform', name:'EventForm', component: EventForm },
    { path: '/bellful', name:'Bellful', component: Bellful },
    { path: '/appgetuserinputs', name:'AppGetUserInputs', component: AppGetUserInputs },
    { path: '/auth', name:'Auth', component: Auth },
    { path: '/apppractice', redirect: {name:'AppPractice'}},
//    { path: '/', name:'Bellful', component: Bellful },
//    { path: '/', name:'AppGetUserInputs', component: AppGetUserInputs },
    { path: '/', name:'AppPractice', component: AppPractice },

]
export default new Router({
    routes: routes
})

