var prevDef = true;
var stopProp = true;
AppUtils = {
    elementFunctions:{},
    buttonhold(element,val) {
        if (val) {
// prevent context menu from coming up for long touch
            AppUtils.elementFunctions.oncontextmenu = element.oncontextmenu;
            AppUtils.elementFunctions.onselectstart = element.onselectstart;
            AppUtils.elementFunctions.ontouchstart = element.ontouchstart;
            AppUtils.elementFunctions.onselect = element.onselect;
            AppUtils.elementFunctions.onfocus = element.onfocus;
            AppUtils.elementFunctions.onblur = element.onblur;
            element.oncontextmenu = function (event) {
                if (prevDef) event.preventDefault();
                if (stopProp) event.stopPropagation();
                //console.log('Context Menu');
                return false;
            };
            element.onselectstart = function (event) {
                if (prevDef) event.preventDefault();
                if (stopProp) event.stopPropagation();
                //console.log('Selected');
                return false;
            };
/*            element.ontouchstart = function (event) {
                if (prevDef) event.preventDefault();
                if (stopProp) event.stopPropagation();
                //console.log('Selected');
                return false;
            };*/
            element.onselect = function (event) {
                if (prevDef) event.preventDefault();
                if (stopProp) event.stopPropagation();
                //console.log('Selected');
                return false;
            };
            element.onfocus = function (event) {
                if (prevDef) event.preventDefault();
                if (stopProp) event.stopPropagation();
                //console.log('Selected');
                return false;
            };
            element.onblur = function (event) {
                if (prevDef) event.preventDefault();
                if (stopProp) event.stopPropagation();
                //console.log('Selected');
                return false;
            };
        }
        else {
            element.oncontextmenu = AppUtils.elementFunctions.oncontextmenu;
            element.onselectstart = AppUtils.elementFunctions.onselectstart;
            element.ontouchstart = AppUtils.elementFunctions.ontouchstart;
            element.onselect = AppUtils.elementFunctions.onselect;
            element.onfocus = AppUtils.elementFunctions.onfocus;
            element.onblur = AppUtils.elementFunctions.onblur;
        }
    }
}
//AppUtils.buttonhold(true); // block browser functions associate with long taps / hold


