import firebase from 'firebase/app';

export const firebaseui = require('firebaseui');
import 'firebase/auth';
//import 'firebase/database'; // not needed for firestore database
import 'firebase/firestore';
import 'firebase/analytics';
import {firebaseConfig} from '../private/AppInfoExport'
/*const admin = require('firebase-admin');
console.log('FieldValue = ',FieldValue)*/
//console.log('FIREBASEUI IS:',firebaseui);
//console.log('FIREBASE CONFIG IS:',firebaseConfig);
//console.log('FIREBASE IS:',firebase);

export const firebaseApp = firebase.initializeApp(firebaseConfig);
// firebase.analytics(); // initialize analytics
//setTimeout(()=>{firebaseAnalytics.logEvent('brainfuljs_appstart');},5000)
console.log('Initialized GA Analytics and Logged Event')
const { Timestamp, GeoPoint } = firebase.firestore;
export { Timestamp, GeoPoint }

export const firebaseAuth = firebaseApp.auth();

export const uiConfig = {
    callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
            // User successfully signed in.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.
            return true;
        },
        uiShown: function() {
            // The widget is rendered.
            // Hide the loader.
            document.getElementById('FirebaseUIloader').style.display = 'none';
        }
    },
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.PhoneAuthProvider.PROVIDER_ID
    ],
    // Terms of service url.
    tosUrl: 'https://www.brainful.com/termsofuse',
    // Privacy policy url.
    privacyPolicyUrl: 'https://www.brainful.com/termsofuse'
};
