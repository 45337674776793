Tone = require('tone');
var _ = require('lodash');
let waterURL = "https://brainful.s3.amazonaws.com/assets/brainful/waterBackground001.flac";
let breathingURL = "https://brainful.s3.amazonaws.com/assets/audio/sounds/breathingClassical003.mp3";
/*_ = {
    once(func) {
        var alreadyCalled = false,
            result;

        return function() {
            if(!alreadyCalled) {
                result = func.apply(this, arguments);
                alreadyCalled = true;
            }

            return result;
        };
    }
};*/
Physio = {
    breathsPerMinute:7,
    beatsPerMinute:60,

    breathsPerMinuteSet(rate) {
        console.log('Setting Breaths Per Minute to:',rate);
        AppAudio.breathsPerMinuteSet(rate);
        //AppAudio.bell();
        Physio.breathsPerMinute = rate;
        AppFirebase.dbAddToUser({breathsPerMinute:Physio.breathsPerMinute})
    },
    beatsPerMinuteSet(rate) {
        Physio.beatsPerMinute = rate;
        AppAudio.breathCycleStart();
    },
}
AppAudio = {

    audioFileNames: {
        background: "https://brainful.s3.amazonaws.com/assets/brainful/waterBackground001.flac",
        //backgroundFade : 'https://brainful.s3.amazonaws.com/assets/audio/sounds/waterBackground001fade001.mp3',
        breathing: "https://brainful.s3.amazonaws.com/assets/audio/sounds/breathingClassical003.mp3",
        breathIn: "https://brainful.s3.amazonaws.com/assets/audio/sounds/breathingClassical003In.mp3",
        breathOut: "https://brainful.s3.amazonaws.com/assets/audio/sounds/breathingClassical003Out.mp3",
        monks: 'https://brainful.s3.amazonaws.com/assets/audio/sounds/omph005.mp3',
        breathInCommand: 'https://brainful.s3.amazonaws.com/assets/audio/sounds/breath-in.mp3',
        breathOutCommand: 'https://brainful.s3.amazonaws.com/assets/audio/sounds/breath-out.mp3',
        dripQuiet: 'https://brainful.s3.amazonaws.com/assets/audio/sounds/drip_quiet.mp3',
        drip: 'https://brainful.s3.amazonaws.com/assets/audio/sounds/drip.mp3',
        pip: 'https://brainful.s3.amazonaws.com/assets/audio/sounds/pip.mp3',
        pop : 'https://brainful.s3.amazonaws.com/assets/audio/sounds/pop.mp3',
        //pop1: 'https://freesound.org/data/previews/413/413854_4337854-hq.mp3',
        loudBell: 'https://brainful.s3.amazonaws.com/assets/audio/sounds/loudbell.mp3',
        loudBellDouble: 'https://brainful.s3.amazonaws.com/assets/audio/sounds/loudbelldouble.mp3',
        loudBellTriple: 'https://brainful.s3.amazonaws.com/assets/audio/sounds/loudbelltriple.mp3',
        chant: 'https://brainful.s3.amazonaws.com/assets/audio/sounds/chantnoiseShortLow.mp3',
        restful: 'https://brainful.s3.amazonaws.com/assets/audio/sounds/restful2_003cs.mp3',
        telephoneRing: 'https://brainful.s3.amazonaws.com/assets/audio/sounds/ringingSound.mp3'
    },
    speechFileNames: {
        numbers: "https://brainful.s3.amazonaws.com/assets/audio/speech/numbersSpoken.mp3",
        phrases: "https://brainful.s3.amazonaws.com/assets/audio/speech/instructionsSpeech001b.mp3"
    },
    sound: [], // structure where the sound objects will be stored
    errors: 0, // have there been audio errors?
    masterGainNode:null, // will be the master gain for the app
    sampler: {}, // where the Tone.Sampler's will be
    speech: {}, // speech audio samples
    breathVolume:0,
    heartVolume:0,
    personalSoundVolume:.001,
    breathPitchDelta:0,
    personalSoundPitchDelta:22, // pitch shift for the personal sound from the standard
    breathCycleRunning:false,
    AppScore:0,
    paused: false,
    targetTimeoutPointer: null, // pointer for setTimeout that controls target
    targetDelay: 0, // how long to delay till next target
    targetDelayMax: 20,
    targetDelayMin: 5,
    eventHappenning: false, // whether inside an event, like a migraine
    supportHappenning: false, // whether on a support call
    initialized: false,
    useBreathful: true,

    init: _.once(()=> {
        let toneOptions = {
            url: "https://brainful.s3.amazonaws.com/assets/audio/sounds/breathingClassical003.mp3",
            loop: false,
            autostart: true
        };
        //AppAudio.Master = new Tone.master(); // this will be the master node to control master gain, mute, etc
        AppAudio.masterGainNode = new Tone.Gain().toDestination();
        Object.keys(AppAudio.audioFileNames).forEach(function (item, key) {
            let keysNames = Object.keys(AppAudio.audioFileNames);
            let keyName = keysNames[key];
            //console.log('item: ',AppAudio.audioFileNames[item],'key: ',key,'keyName: ',keyName);
            let audioFilename = AppAudio.audioFileNames[item];
            let toneOptions = {url: audioFilename, loop: false, autostart: false};
            let soundObject = new Tone.Player(toneOptions).connect(AppAudio.masterGainNode);
            AppAudio.sound[keyName] = soundObject;
        });
        toneOptions = {url: AppAudio.speechFileNames.numbers, loop: false, autostart: false};
        let speechObject = new Tone.Player(toneOptions).connect(AppAudio.masterGainNode);
        AppAudio.speech.numbers = speechObject;
        toneOptions = {url: AppAudio.speechFileNames.phrases, loop: false, autostart: false};
        speechObject = new Tone.Player(toneOptions).connect(AppAudio.masterGainNode);
        AppAudio.speech.phrases = speechObject;

        AppAudio.initBreathSampler();
        AppAudio.initPersonalSound();
        AppAudio.initialized = true;
        Tone.loaded().then(() => {
            Object.keys(AppAudio.audioFileNames).forEach(function (item, key) {
                let keysNames = Object.keys(AppAudio.audioFileNames);
                let keyName = keysNames[key];
                //console.log('item: ',AppAudio.audioFileNames[item],'key: ',key,'keyName: ',keyName);
                let audioFilename = AppAudio.audioFileNames[item];
                let soundObject = AppAudio.sound[keyName];
                let loadedCorrectly = soundObject.loaded;
                let AudioErrors = false;
                if (loadedCorrectly) {
                }
                //console.log('loaded: ',keyName);
                else {
                    AppAudio.errors += 1;
                    console.log('*** Error, AppAudio did not load: ', keyName);
                }
                if (!AppAudio.errors) {
                    if (this.logit) console.log('All sounds loaded correctly.');
                }
            });
        });
    }),
    start() {
        Tone.start();
    },
    initPersonalSound(){
        AppAudio.personalSound = {};
        AppAudio.personalSound.personalTemolo = new Tone.Tremolo(8,0); // ADD BACK THE START !!!
        AppAudio.personalSound.personalSoundGain = new Tone.Gain();
        AppAudio.personalSound.personalSoundGain.gain.rampTo(1, Tone.now());

        AppAudio.personalSound.panner = new Tone.Panner3D(0,0,0);
//        personalSoundPanner.panningModel('HRTF');
        AppAudio.personalSound.panner.setPosition(10,0,0);

        AppAudio.personalSound.sound = new Tone.Sampler({
            urls: {
                "B4": "B4.mp3",
            },
            release: 8,
//        baseUrl: "https://tonejs.github.io/audio/salamander/",
            baseUrl: "https://brainful.s3.amazonaws.com/assets/audio/samples/harmonium/"
        });
        AppAudio.personalSound.sound.chain(AppAudio.personalSound.personalTemolo,
            AppAudio.personalSound.panner,
            AppAudio.personalSound.personalSoundGain,
            AppAudio.masterGainNode);
    },
    once(func) {
        func.apply(this, arguments);
        return;
        if (typeof func === 'undefined') func.hasRun = true;
        if (func.hasRun) return;
        func.hasRun = true;
    },
    sessionStart: _.once(()=> {
        console.log('Starting session');
        Tone.start();
        AppAudio.play('loudBell');
        AppAudio.masterVolumeSet(.1);
        //AppAudio.masterFade(0,0);
        AppSensorListener.init();
        AppAudio.backgroundSoundStart();
//        AppAudio.initPersonalSound();
        AppAudio.masterFade(1,4);
        msg = {"command": "sessionStart"};AppMsg.send(msg);

        // Send appSync data every second
        Tone.Transport.scheduleRepeat(() => {
            msg = {"command": "appSync","volume":AppAudio.masterVolumeGet()};
            AppMsg.send(msg);
        }, "1");

        // Speak instructions
        Tone.Transport.scheduleOnce(()=>{AppAudio.sessionCompleted()},Tone.now() + 7 * 60 ); // end of session
        Tone.Transport.scheduleOnce(()=>AppSensorListener.stop(),Tone.now() + 15 * 60); // turn off after fixed time
        Tone.Transport.scheduleOnce(()=>AppAudio.speakPhrase(0),"+1");
        Tone.Transport.start();

        // Queue first target
        AppAudio.targetQ(15,30);  // queue first target
        setTimeout(async ()=>{
            AppAudio.userUpdate();
        },10*1000)
        Tone.Transport.scheduleOnce(()=>AppData.createSession(),30);  // if user gets 30s in, write session to db
    }),
    async userUpdate (){
        let appUser = await AppFirebase.getAppUser();
        let msg = {"command": "updateUser","appUser":appUser};AppMsg.send(msg);
        console.log('UpdateUser: ',appUser,'msg: ',msg);
    },
    sessionCompleted() {
        AppAudio.speakPhrase(4); //"Session completed, etc"
        let appUser = AppFirebase.appUser;
        let msg = {"command": "sessionEnd","appUser":appUser};AppMsg.send(msg);
        console.log('UpdateUser: ',appUser,'msg: ',msg);
    },
    sessionStop: _.once(()=> {
        AppAudio.pauseAll();
        msg = {"command": "sessionStop"};
        AppMsg.send(msg);
        AppAudio.targetClear();
        AppSensorListener.stop();
    }),
    muteAll() {
        Tone.Destination.mute = !Tone.Destination.mute;
    },
    bell() {
        AppAudio.play('loudBell');
    },
    beep() {
        AppAudio.play('loudBell');
    },
    telephoneRingPlay() {
        AppAudio.play('telephoneRing');
    },
    speakNumber(num) {
        // numbers sprite is a single audio file with all of the numbers spoken, at times specified in numbersSprite
        let startTime = numbersSprite[num] / 1000;
        let endTime = numbersSprite[num+1] / 1000;
        let timeDiff = endTime - startTime;
        AppAudio.speech.numbers.start(Tone.now(),startTime,timeDiff);
        console.log('Speaking: ',num);
    },
    speakPhrase(num) {
        // 0: Soundful, 1: Breathful, 2: Restful Sleep, 3: Great, 4: Session Completed
        let startTime = phraseSprite[num] / 1000;
        let endTime = phraseSprite[num+1] / 1000;
        let timeDiff = endTime - startTime;
        AppAudio.speech.phrases.start(Tone.now(),startTime,timeDiff);
        console.log('Speaking: ',num);
    },
    test() {
        let currentVolume = AppAudio.masterGainNode.gain.value;
        AppAudio.bell();
        return;
        AppAudio.initPersonalSound();
        return;
        AppAudio.loop('background');
        //AppAudio.loop('breathing');
        return;
        setTimeout(()=>{
            AppAudio.jump();
        },8000+7000)
        setTimeout(()=>{
            Tone.Transport.bpm.value = 220;
        },8000+18000)
        return
        AppAudio.breathingRate(1);
        AppAudio.testSound = new Tone.Player({
            url: breathingURL,
            loop: true,
            autostart: true,
        })
    },
    playTestSound(){
        AppAudio.sampler.breathIn.triggerAttackRelease("C3",'1n');
        AppAudio.sampler.breathIn.triggerAttackRelease("C3",'1n',Tone.now()+Tone.Time("3n"));
    },
    waveButton() {
        AppAudio.breathCycleStart();
    },
    play(name) {
        AppAudio.sound[name].start();
    },
    pause(name) {
        AppAudio.sound[name].stop();
    },
    loop(name) {
        AppAudio.sound[name].loop = true;
        AppAudio.play(name);
    },
    //This code is used to initialize the audio for the breath-in/breath-out cycle
    initBreathSampler(){
        AppAudio.sampler.breath = new Tone.Sampler({
            urls: {
                "F2": "https://brainful.s3.amazonaws.com/assets/audio/sounds/breathingClassical003Out.mp3",
                "C3": "https://brainful.s3.amazonaws.com/assets/audio/sounds/breathingClassical003In.mp3",
                "C6": "https://brainful.s3.amazonaws.com/assets/audio/sounds/oneHeartbeatSoft.mp3",
            },
            attack : .5 ,
            release : 1 ,
        }).connect(AppAudio.masterGainNode);
        AppAudio.samplerBreathOut = new Tone.Sampler({
            urls: {
                "C4": "https://brainful.s3.amazonaws.com/assets/audio/sounds/breathingClassical003Out.mp3",
            },
        }).connect(AppAudio.masterGainNode);
        AppAudio.heartGain = new Tone.Gain(.05).connect(AppAudio.masterGainNode);
        AppAudio.sampler.heart = new Tone.Sampler({
            urls: {
                "C6": "https://brainful.s3.amazonaws.com/assets/audio/sounds/OneHeartbeat.mp3",
            },
        }).connect(AppAudio.heartGain);
        AppAudio.breathsPerMinuteSet(Physio.breathsPerMinute);
    },
    breathsPerMinuteSet(val){
        let secondsPerBreath = val; // 8 second long breathing sound
        let beatsPerBreath = 8; // two measures for each breath in Tonejs notation = '2m'
        Tone.Transport.bpm.value = val * beatsPerBreath;
        if (this.logit) console.log('Setting BPM to:',Tone.Transport.bpm.value);
    },
    breathCycleStart() {
        if (AppAudio.breathCycleRunning) return;
        AppAudio.breathCycleRunning = true;
        // velocity is 0-1 volume of the note
        // AppAudio.sampler.breath.triggerAttackRelease(note,duration,time,velocity);
        AppAudio.sampler.breath.set({volume:1,envelope: {attack:"16n",decay:0,sustain:1,release:"8n"}}); //ADSR.  Release starts at the end of the note.  If you don't specify a duration it will never perform the release, like triggerAttack.
        // attack can be in seconds or in eg "8n"
        // sustain is a velocity value
        // decay are both in seconds, for decay time either to sustain level or to 0 after the note ends.  Can be "8n"
        Tone.Transport.scheduleRepeat(AppAudio.breathingRepeat,"2m"); // repeat every 2 measures at bpm
        Tone.Transport.scheduleRepeat(AppAudio.heartBeatRepeat,"4n"); // repeat every quarter note at bpm
        Tone.Transport.start();
    },
    // Ain't nothin here at all!!!
    breathingRepeat(){
        AppAudio.breathVolume = .5;
        let breathInFrequency = Tone.Frequency("C3").transpose(AppAudio.breathPitchDelta);
        let personalSoundFrequency = Tone.Frequency("C3").transpose(AppAudio.personalSoundPitchDelta).harmonize([0, 3, 7]);
        let breathOutFrequency = Tone.Frequency("F2").transpose(AppAudio.breathPitchDelta);
        //Breath In
        AppAudio.sampler.breath.triggerAttackRelease(breathInFrequency,"2n",Tone.now(),AppAudio.breathVolume); // will pick the closest sample that's defined and adjust from there
        let msg = {"command":"breathInStart"};AppMsg.send(msg);

        //Personal Sound In
        AppAudio.personalSound.sound.triggerAttackRelease(personalSoundFrequency,"2n",Tone.now(),AppAudio.personalSoundVolume);

        //Breath Out
        AppAudio.sampler.breath.triggerAttackRelease(breathOutFrequency,"1n",Tone.now() + 3.5 * Tone.Time("4n"),AppAudio.breathVolume); // "+2" = Tone.now() + Tone.Time("2n");

        //Personal Sound Out
        AppAudio.personalSound.sound.triggerAttackRelease(Tone.Frequency("g3").harmonize([0, 3, 7]),"1n",Tone.now() + 3.5 * Tone.Time("4n"),AppAudio.personalSoundVolume);
    },
    // Individual heart beat sound
    heartBeatRepeat(){
        AppAudio.heartVolume = 1;
        AppAudio.sampler.heart.triggerAttackRelease("C6","4n",Tone.now(),AppAudio.heartVolume); // will pick the closest sample that's defined and adjust from there
    },
    processBreathScores(breathScores) {
        if (!AppAudio.useBreathful) return;
        let breathScore = breathScores[breathScores.length-1];
        if (AppAudio.eventHappenning)
            AppAudio.speakNumber(breathScore);
    },
    heartVolumeSet(target,timeSec){
        AppAudio.heartGain.gain.linearRampToValueAtTime(target, Tone.now() + timeSec);
    },
    masterVolumeGet() {
        return AppAudio.masterGainNode.gain.value;
    },
    masterVolumeSet(val) {
        AppAudio.masterGainNode.gain.value = val;
    },
    masterFade(target,timeSec) {
        let currentMasterGain = AppAudio.masterGainNode.gain.value;
//        AppAudio.masterGainNode.gain.value = currentMasterGain; // anchor the ramp to start at 1
        AppAudio.masterGainNode.gain.linearRampTo(target, timeSec);
        return;
        // check gain to see if it's changing correctly
        setInterval(()=>{console.log('Time: ',Tone.now(),' Master Gain: ',AppAudio.masterGainNode.gain.value)},250);
    },
    masterFadeDownPsychometric(durationSec,delaySec) {
        let fadeCurve = [1 , 0.9933 ,   0.9890  ,  0.9820  ,  0.9707  ,  0.9526  ,  0.9241  ,  0.8808  ,  0.8176   , 0.7311  ,  0.6225  ,  0.5000  ,  0.3775 ,   0.2689 ,   0.1824, 0.1192   , 0.0759 ,   0.0474   , 0.0293  ,  0.0180  ,  0.0110  ,  0.0067 , 0];
        fadeCurve = [1.0000  ,  0.7943  ,  0.6310  ,  0.5012   , 0.3981  ,  0.3162  ,  0.2512  ,  0.1995  ,  0.1585  ,  0.1259 ,   0.1000  ,  0.0794  ,  0.0631  ,  0.0501  ,0.0398 ,   0.0316  ,  0.0251  ,  0.0200  ,  0.0158  ,  0.0126  ,  0.0100, 0];
        AppAudio.masterGainNode.gain.setValueCurveAtTime(fadeCurve, Tone.now() + delaySec, durationSec); // [array to interp] start time, duration
        return;
        setInterval(()=>{console.log('Time: ',Tone.now(),' Master Gain: ',AppAudio.masterGainNode.gain.value)},250);
    },
    drip() {
        AppAudio.sound.dripQuiet.start();
    },
    pop() {
        AppAudio.sound.pop.start();
    },
    pauseAll() {
        if (AppAudio.paused){
            Tone.context.rawContext.suspend();
            Tone.Transport.stop();
        }
        else {
            Tone.context.rawContext.resume();
        }
    },
    resumeAll() {
        Tone.context.rawContext.resume();
        Tone.Transport.start()
    },
    now()
    // alternative to performance.now() that is more accurate, sync'd to audio, and stops with pauseAll.
    // Returns time in SECONDS
    {
        let ToneExists = !(typeof Tone === "undefined");
        if (ToneExists)
            return Tone.now();
        else
            return performance.now()/1000;
    },
    silence(){
        Tone.Master.volume.input.linearRampToValueAtTime(0,0,0);
    },
    unSilence(){
        Tone.Master.volume.input.linearRampToValueAtTime(1,0,0);
    },
    buzz(){
        //AppAudio.pauseAll();setInterval(()=>AppAudio.resumeAll(),400);return;
        Tone.Master.volume.input.linearRampToValueAtTime(.45,0,0);
        setInterval(()=>this.unSilence(),800);
    },
    backgroundSoundStart(){
        AppAudio.loop('background');
    },
    breathingSoundStart(){
        AppAudio.loop('breathing');
    },
    targetStart(){
        AppAudio.masterFadeDownPsychometric(60,0);
        let msg = {"command":"targetStart","time":Tone.now()};AppMsg.send(msg);
        //AppAudio.masterFade(0,10);
    },
    targetStop(){
        AppAudio.masterFade(1,.35);
        AppAudio.targetClear();
        let msg = {"command":"targetStop","time":Tone.now()};AppMsg.send(msg);
    },
    targetClear() {
        if (AppAudio.targetTimeoutPointer) clearTimeout(AppAudio.targetTimeoutPointer);
    },
    targetQ(targetDelayMin,targetDelayMax) {
        AppAudio.targetClear();
        let range = targetDelayMax - targetDelayMin;
        AppAudio.targetDelay = ((Math.random() * range) + targetDelayMin);
        AppAudio.targetTimeoutPointer = setTimeout(()=>AppAudio.targetStart(),AppAudio.targetDelay * 1000);
        let msg = {"command":"targetQ","time":AppAudio.targetDelay};AppMsg.send(msg);
        console.log(msg);
    },
    infinityButton: ()=>{
        console.log('Tapped Infinity');
        let currentVolume = AppAudio.masterGainNode.gain.value;
        let correct = false;
        if (currentVolume < 1) {
            AppAudio.AppScore = Math.round(currentVolume * 100);
            if (!AppAudio.eventHappenning)
                AppAudio.speakNumber(AppAudio.AppScore);
            if (this.logit) console.log('Hit. Score: ',AppAudio.AppScore);
            correct = true;
            }
        else {
            AppAudio.AppScore = 0;
            AppAudio.drip();
            //AppAudio.buzz();
            if (this.logit) console.log('Miss. Score: ',AppAudio.AppScore);
        }
        //app2.update();
        AppAudio.targetStop(); // if not communicating to servers
        let msg = {"command":"appTap","score":AppAudio.AppScore};AppMsg.send(msg);
        AppAudio.targetQ(AppAudio.targetDelayMin,AppAudio.targetDelayMax);
        return correct;
    },
    createPersonalSound(options) {
        AppAudio.test.sound =new Tone.Synth();
        AppAudio.test.sound = new Tone.Sampler({
            urls: {
                "C3": "https://brainful.s3.amazonaws.com/assets/audio/sounds/breathingClassical003In.mp3",
            },
        });
        let tremoloOptions = {
            depth : .85,
            frequency : 20,
            spread : 1,
            type:'square'
        }
        const tremolo = new Tone.Tremolo(tremoloOptions).start(); // Tremolo = sine wave amplitude envelope
/*
        const tremolo = new FX({
            effect: Tone.Tremolo,
            options:  [10,.75],
            bypass: false,
        });
*/
        const phaser = new Tone.Phaser(10,1,100);

        const distortion = new FX({
            effect: Tone.Distortion,
            options: { distortion: options.distortion },
            bypass: false,
        });
        const reverb = new FX({
            effect: Tone.Reverb,
            options: options.reverbDelay,
            bypass: false,
        });
        const eq3 = new FX({
            effect: Tone.EQ3,
            options: options.EQ3,
            bypass: false,
        });
        const filter = new Tone.Filter(options.filterFrequency, "lowpass");
//        const chorus = new Tone.Chorus(4, 2.5, 0.5);
        const chorus = new Tone.Chorus(0, 0, 0);
        const panner = new FX({
            effect: Tone.Panner3D,
            options: options.panner,
            bypass: false,
        });
        AppAudio.test.sound.chain(tremolo,Tone.Destination);
    },
}


AppAudio.init();

/* wrapper for effect class / bus */
class FX extends Tone.ToneAudioNode {
    constructor(options) {
        super();
        this.effect = new options.effect(options.options); // create effectNode in constructor
        this._bypass = options.bypass;
        this._lastBypass = options.bypass;

        // audio signal is constantly passed through this node,
        // but processed by effect only, if bypass prop is set to `false`
        this.input = new Tone.Gain();
        this.output = new Tone.Gain();

        this.effect.connect(this.output);

        this.activate(!options.bypass); // initialize input node connection
    }

    get bypass() {
        return this._bypass;
    }

    set bypass(val) {
        if (this._lastBypass === val) return;

        this._bypass = Boolean(val);
        this.activate(!val);
        this._lastBypass = val;
    }
    applyEffectToSound(inputSoundNode) {
//        inputSoundNode.input.disconnect();
        inputSoundNode.input.connect(this.effect);
        return inputSoundNode;
    }
    /*
   activate effect (connect input node), if bypass == false
   */
    activate(doActivate) {
        if (doActivate) {
            this.input.disconnect();
            this.input.connect(this.effect);
        } else {
            this.input.disconnect();
            this.input.connect(this.output);
        }
    }

    toggleBypass() {
        this.bypass = !this._bypass;
    }

    dispose() {
        super.dispose();
        this.effect.dispose();
        return this;
    }
}
let numbersSprite = [0	,
    1111	,
    1990	,
    2945	,
    3982	,
    4859	,
    5977	,
    7049	,
    8008	,
    8949	,
    10024	,
    10874	,
    11905	,
    12983	,
    14078	,
    15333	,
    16543	,
    17750	,
    19006	,
    20046	,
    21213	,
    22234	,
    23466	,
    24880	,
    26245	,
    27581	,
    28985	,
    30428	,
    31789	,
    33038	,
    34427	,
    35522	,
    36780	,
    38221	,
    39583	,
    40913	,
    42335	,
    43804	,
    45201	,
    46480	,
    47809	,
    48867	,
    50161	,
    51554	,
    52939	,
    54314	,
    55763	,
    57246	,
    58677	,
    59982	,
    61394	,
    62402	,
    63589	,
    65004	,
    66323	,
    67709	,
    69185	,
    70666	,
    72073	,
    73338	,
    74677	,
    75785	,
    77142	,
    78555	,
    80023	,
    81442	,
    82932	,
    84446	,
    85957	,
    87332	,
    88815	,
    89958	,
    91349	,
    92868	,
    94374	,
    95752	,
    97280	,
    98834	,
    100284	,
    101623	,
    103098	,
    104044	,
    105218	,
    106508	,
    107736	,
    108954	,
    110263	,
    111636	,
    112901	,
    114099	,
    115328	,
    116444	,
    117693	,
    119117	,
    120517	,
    121869	,
    123311	,
    124792	,
    126189	,
    127458	,
    128826	,
    130160	,
    ((2 * 60)+10)*1000 + 664
];
let phraseSprite = [
    0,
    1154,
    2246,
    3908,
    4832,
    11229,
    12000
];
