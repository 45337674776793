// Create Human-Readable IDs, rather than using random hashes
window.AppReadableId = ()=> {
    var one = ['very','truly','wildly','not','sorta','kinda','madly','only','still','never','really','always','finally'];
    var two = ['smart','stoked','silly','glorious','rad','fun','courageous','massive','hot','lovely','cool','wild','loud','benevolent','empathic'];
    var three= ['elephant','mule','goat','donkey','dog','lion','fish','dolphin','pony','unicorn','eagle','elvis'];
    var value = '';
    let dt = new Date;
    let temp = '';
    value += dt.getFullYear();
    temp = (dt.getMonth() + 1).toString()
    value += temp.padStart(2,'0');
    temp = dt.getDate().toString()
    value += temp.padStart(2,'0');
    value += '_';
    value = value + one[Math.round((Math.random()*12))]+'_';
    value = value + two[Math.round((Math.random()*14))]+'_';
    value = value + three[Math.round((Math.random()*10))]+'_';
    value = value + Math.round((Math.random()*99999));

    return value;
}
