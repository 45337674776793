<template name="AppPractice">
  <div>
    <div id="app1" class="vueApp">
      <div class="backgroundCover"></div>
      <div class="buttonContainerTop">
        <router-link to="/appsettings">
          <div id="settings-button" class = "circleFilledSmall iconGear"><div class="topBarIcon">Settings</div></div>
        </router-link>
<!--
        <router-link to="/bellful">
          <div id="bellful-button" class = "circleFilledSmall iconBell"><div class="topBarIcon">Bellful</div></div>
        </router-link>
-->
                <router-link to="/appgetuserinputs">
                  <div id="events-button" v-on:click="$root.shouldReportEvent = false" class = "circleFilledSmall iconEvents"><div class="topBarIcon">Events</div></div>
                </router-link>
        <div id="start-migraine" v-on:click="buttonPressMigraine($event)" class = "circleFilledSmall iconBrain" v-bind:class="{ backgroundColorActive: $root.inEvent }"><div class="topBarIcon">Migraine</div></div>
        <div id="support-button" v-on:click="buttonPressSupport(true,$event)" class = "circleFilledSmall iconSupport"><div class="topBarIcon">Consult</div></div>
        <div id="refresh-button" v-on:click="buttonPressPageReload($event)" class = "circleFilledSmall iconRefresh" ><div class="topBarIcon">Reload</div></div>
      </div>
      <br>
      <h1 v-on:click="buttonPressManualLoadAudio($event)" class="AppTitle">SOUNDFUL</h1>
      <div class="appInfo">v0.36 : {{firstName}} : {{breathsPerMinute}} BrPM</div>

      <div v-if="$root.shouldReportEvent" class="noticeBox">
        <div @click="$root.shouldReportEvent=false;" class="closeX"><font-awesome-icon icon="times" /></div>
        <p>
          <span class="noticeMsg">
            <font-awesome-icon size="lg" icon="comments">
            </font-awesome-icon><br><br>
            &nbsp PLEASE REPORT ON EVENTS<br>BEFORE STARTING EVERY SESSION<br>& AFTER ENDING SESSIONS DURING MIGRAINES
        </span>
          </p>
      </div>
      <div class="buttonContainer">
        <div id="start-button" v-on:click.once="buttonPressStart($event)" class = "circleFilled startTriangle"><div class="startTriangle"></div></div>
        <div id="stop-button" v-on:click="buttonPressPause($event)" class = "circleFilled iconPause"><div class="startTriangle"></div></div>
        <div id="wave-button" v-on:click.once="buttonPressWave($event)" class = "circleFilled breathWave"><div class="startTriangle"></div></div>
      </div>
      <div class="centerCircleContainer" v-on:touchend="buttonPressInfinity($event)" v-on:contextmenu="preventDefault($event)" v-on:select="preventDefault"  >
        <div id="infinity-button" class="centerCircle">
          <img class="centerCircleImg" src='../../../public/assets/images/infinity-logo.png'>
        </div>
      </div>

      <div id="appTimer" class="appTimer">
        <p>
    <span>
        Time:
      {{larry}}
        {{AppTime}}<br><br>
        {{AppSocketConnected}}<br><br>
      <!--        Score:
              {{AppScore}}-->
  </span>

        </p></div>

      <div id="messages" contenteditable></div>
    </div>

  </div>

</template>

<script >
let get = import('lodash/get')
//console.log('AppPractice Component')
export default {
  name: 'AppPractice',
  data() {
    return {
      bundler: "Parcel",
      AppTime: '00:00',
      AppScore:'0',
      AppSocketConnected:'',
      nameField:'',
      message:'hello vue',
      firstName:'',
      breathsPerMinute: 8,
    };
  },
  methods: {
    preventDefault: (event) => {
      event.preventDefault();
      console.log(`canceled a ${event.type} event`);
    },
    update: function () {
      this.logit = true;
      let timeRatio = 1;
      let nw = Tone.now() * 1000 * timeRatio;
      let hrs = Math.floor(nw/(60*60*1000)%60).toString().lpad("0",2);
      let mins = Math.floor(nw/(60*1000)%60).toString().lpad("0",2);
      let secs = Math.round(nw/(1000)%(60)).toString().lpad("0",2);
      if (hrs < 1)
        this.AppTime = mins + ':' + secs;
      else
        this.AppTime = hrs + ':' + mins + ':' + secs;
      //console.log('Time:',app2.AppTime);
      this.AppScore = AppAudio.AppScore;
      if (AppMsg.socketOpen)
        this.AppSocketConnected = 'Connected';
      if (Tone.now() > 5) {
        if (!AppMsg.socketOpen) {
          this.AppSocketConnected = '- No Server Connection -';
        }
        if (!navigator.onLine) {
          this.AppSocketConnected = 'Offline';
        }
      }
      this.breathsPerMinute = Physio.breathsPerMinute;
      /*   if (getWidth()<500) {
           function quarter() {
             window.resizeTo(
                 window.screen.availWidth / 1,
                 window.screen.availHeight / 1
             );
           }
           console.log('***resizing***')
         }*/
      this.updateFromDB();
    },
    updateFromDB: async function() {
      if (this.firstName !== '') return;
      let appUser = await AppFirebase.getAppUser();
      if ((typeof appUser !== 'undefined') && (appUser !== null)){
        this.firstName = appUser.firstName;
        this.breathsPerMinute = appUser.breathsPerMinute;
        Physio.breathsPerMinuteSet(this.breathsPerMinute);
      }
    },
    buttonPressMigraine: function(event) {
      AppAudio.eventHappenning = !AppAudio.eventHappenning;
      this.$root.inEvent = !this.$root.inEvent;
      if (this.logit) console.log('buttonPressMigraine Event:',event);
      let msg = {};
      if (this.logit) console.log('Migraine Event Happening: ',AppAudio.eventHappenning);
    },
    buttonPressX: function(event) {
      if (this.logit) console.log('Buttonpress Event:',event.target.tagName);
    },
    buttonPressStart: function(event) {
      if (this.logit) console.log('Buttonpress Event:',event.target.tagName);
      event.target.classList.add('backgroundColorHighlight');
      AppAudio.sessionStart();
    },
    buttonPressPause: function(event) {
      AppAudio.paused = !AppAudio.paused;
      this.$root.prepost = 'post';
      if (this.$root.inEvent) this.$root.shouldReportEvent = true;
      if (this.logit) console.log('buttonPressMigraine Event:',event);
      let msg = {};
      if (AppAudio.paused) {
        event.target.classList.add('backgroundColorActive');
        msg = {"command": "pauseStart"};AppMsg.send(msg);
        AppAudio.pauseAll();
      }
      else {
        event.target.classList.remove('backgroundColorActive');
        msg = {"command": "pauseEnd"};AppMsg.send(msg);
        AppAudio.resumeAll();
      }
    },
    buttonPressWave: function(event) {
      if (this.logit) console.log('Buttonpress Event:',event.target.tagName);
      event.target.classList.add('backgroundColorHighlight');
      AppAudio.waveButton();
    },
    buttonPressInfinity: function(event) {
      if (this.logit) console.log('Buttonpress Event:',event.target.tagName);
      window.centerCircleContainer = document.getElementById("infinity-button");

      let correct = AppAudio.infinityButton();
      let classToAdd = 'backgroundColorHighlight';
      console.log('Correct:',correct);
      if (!correct)
        classToAdd = 'backgroundColorMiss';
      centerCircleContainer.classList.add(classToAdd);
      setTimeout(()=>{centerCircleContainer.classList.remove(classToAdd);},100);
    },
    buttonPressSupport: function(value,event) {
      AppAudio.supportHappenning = !AppAudio.supportHappenning;
      if (this.logit) console.log('Buttonpress Event:',event.target.tagName);
      let msg = {};
      if (AppAudio.supportHappenning) {
        AppWebrtc.go();
        event.target.classList.add('backgroundColorActive');
        msg = {"command": "supportStart"};AppMsg.send(msg);
      }
      else {
        AppWebrtc.hangup();
        event.target.classList.remove('backgroundColorActive');
        msg = {"command": "supportEnd"};AppMsg.send(msg);
      }
      if (this.logit) console.log('Support Event Happening: ',AppAudio.supportHappenning);
    },
    buttonPressPageReload: function(event) {
      if (this.logit) console.log('Buttonpress Event:',event.target.tagName);
      let msg = {"command": "AppReload"};
      AppMsg.send(msg);
      location.reload();
    },
    buttonPressManualLoadAudio: function() {
      AppAudio.start();
      AppAudio.bell();
    }
  },
  mounted() {
    window.AppMainComponent = this;
    //var centerCircleContainer = document.getElementById("infinity-button");
    //AppUtils.buttonhold(centerCircleContainer,true); // remove browser functions on long-tap of infinity button to allow long hold/touchend
    let timeRatio = 1;
    setInterval(()=>{
      this.update()},5000/timeRatio);
    return;
    let appUser = AppFirebase.getAppUser();
    let firstName = get(appUser,'firstName','');
    this.data.firstName = firstName;
    let breathsPerMinute = get(appUser,'breathsPerMinute','');
    this.data.breathsPerMinute = breathsPerMinute;
    }
};
String.prototype.lpad = function(padString, length) {
  var str = this;
  while (str.length < length)
    str = padString + str;
  return str;
}
function getWidth() {
  if (self.innerWidth) {
    return self.innerWidth;
  }

  if (document.documentElement && document.documentElement.clientWidth) {
    return document.documentElement.clientWidth;
  }

  if (document.body) {
    return document.body.clientWidth;
  }
}
</script>

<style lang="scss" scoped>
p {
  color: white;
}
.AppTitle:hover {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
.userName {
  position:absolute;
  bottom:1%;
}
.circleFilledSmallEvent {
  height: 7em;
  width: 7em;
  background-color: rgb(190, 206, 96,0.45);
  border-radius: 50%;
  margin: 1.5em;
  content:'x';
}
.backgroundColorActive {
  background-color: #3700B3;
}
.backgroundColorHighlight {
  background-color: rgba(187, 187, 187, 0.90);
}
.backgroundColorMiss {
  background-color: rgba(206, 96, 96, 0.45);
}
.circleFilledEvent {
  height: 10em;
  width: 10em;
  /*background-color: rgba(58, 118, 133, 0.76);*/
  background-color: rgba(206, 96, 96, 0.45);
  border-radius: 50%;
  display: inline-block;
  margin: 4em;
}
.hideElement {
  display:none;
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
.appInfo {
  position: absolute;
  bottom:1%;
  left: 1%;
  text-align: left;
  font-size: 2em;
  width:auto;
  opacity: .7;
  background: transparent; color:white;text-align: center; opacity: .75;
}
.buttonContainerTop {
  position: absolute;
  top: 0%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width:100%;
  background-color: rgba(35, 35, 35, 0.2);
  background-color: rgba(128, 128, 128, 0.42);
  height:9%;
}
.noticeMsg {
  color:white;
  padding:1.2em;
  border-radius:.2em;
  line-height: 1.5em;
}
.noticeBox {
  position: absolute;
  padding:2%;
  padding-top: 5%;
  top: 15%;
  width: 100%;
  font-size: 2em;
  height:18%;
  background-color:#018786;
  text-align: center;
  z-index: 10;
}
.closeX {
  font-size: 1em;
  color:white;
  float:right;
}

</style>
