<template lang="html">
  <div>AUTH
  <div id="firebaseui-auth-container"></div>
  </div>
</template>
<script>
import {firebaseApp,firebaseAuth,uiConfig,firebaseui} from '../../firebase';
export default {
  name: 'auth',
  mounted() {
    console.log('FirebaseAuth = ',firebaseAuth);
    console.log('FirebaseUI = ',firebaseui);
      var ui = new firebaseui.auth.AuthUI(firebaseAuth);
      ui.start('#firebaseui-auth-container', uiConfig);
  },
}
</script>