var isWebview = require('is-ua-webview')

// Version and overall information
window.AppInfo = {
    build: "Alpha0108a",
    emailL:'',
    userId:'',
    mode:'', //use dev for dev mode, set in createAccount
    debugMode:false
}
// Initialize Firebase
let firebaseConfig = {
    apiKey: "AIzaSyBD9RcDoNZY8lqmQ9RHYlfmiFbnqRnGmKk",
    authDomain: "brainful-4cdcv.firebaseapp.com",
    databaseURL: "https://brainful-4cdcv.firebaseio.com",
    projectId: "brainful-4cdcv",
    storageBucket: "brainful-4cdcv.appspot.com",
    messagingSenderId: "501438443061",
    appId: "1:501438443061:web:447d18004dbfaf3a348b7c",
    measurementId: "G-SE67FCCKLL"
};

let __DEV__ =true;
if (__DEV__) {
    console.log('Running app in mode: DEVELOPMENT');
    AppInfo.firebasePrefix = `dev-`;
}
else {
    console.log('Running app in mode: PRODUCTION');
    AppInfo.firebasePrefix = ``;
}

