<template name="EventForm">
  <div>
    <div class="closex-container">
      <div @click="clickMethod" class="closeX"><font-awesome-icon icon="times" />X</div>
    </div>
    <div class='iframe-container'>
      <iframe src="./eventform.html" frameborder="0" class = "iframe-style"/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'EventForm',
  data() {
    return {
      msg: 'Welcome to Your Vue.js App',
      bundler: 'Parcel'
    }
  },
  methods: {
    clickMethod() {
      console.log('clicked');
      this.$router.go(-1);
      //add code that you wish to happen on click
    }
  }
}
</script>

<style scoped>
p {
  color: red;
}

.iframe-container {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  top: 3%;
  height: 97%;
  position: absolute;
  width: 100%;
  opacity: .9;

}
.iframe-style {
overflow:hidden;
  height:100%;
  width:100%;
  position:fixed;
  background-color: #83CBCC;
}
.closex-container {
  background-color: #83CBCC;
  position: absolute;
  top: 0%;
  height:3%;
  width:100%;
  opacity: .9;
}
.closeX {
  position: absolute;
  top: 0%;
  right: 1%;
  font-size: 3em;
  color:white;
}
</style>