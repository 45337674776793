<template name="AppGetUserInputs">
  <div class="container">
    <div @click="clickXMethod" class="closeX"><font-awesome-icon icon="times" />X</div>
    <h1>MIGRAINE REPORTING</h1>
    <div class="eventReporting">
      <div class="currentlyHavingMigraine">
        <h3>Are you having a migraine?</h3>
        <div class="buttonGroup">
          <button @click="values.inEvent=false;$root.inEvent=false;" class="appBtn">No / Unsure</button>
          <button @click="values.inEvent=true;$root.inEvent=true;" class="appBtn">Yes</button>
        </div>
      </div>

      <div v-show="answeredIfInEvent">
        <!--        NOT Having an Event  -->
        <div v-show="values.inEvent===false">
          <br>
          <h3>Has a migraine ended that you haven't reported yet?</h3>
          <div class="buttonGroup">
            <button @click="values.endingEvent=false;done=true;" class="appBtn">No</button>
            <button @click="values.endingEvent=true" class="appBtn">Yes</button>
          </div>

          <div v-if="values.endingEvent">
            <br>
            <h4>When did your migraine end (last symptoms felt when)?</h4>
            <FormulateInput v-model="values.endDate" type="date" name="date" validation="required" label=""></FormulateInput>
            <FormulateInput v-model="values.endTime" v-on:change="showIntensityRating=true" type="time" name="time" validation="required"
                            label=""></FormulateInput>
            <div v-if="showIntensityRating" class="numericRating">
              <h4>What PERCENTILE Was this Migraine Compared with Other Migraines Over the Last 12 Months</h4>
              <FormulateInput type="select" v-model="values.comparison" v-on:change="showLinkToQx=true;" validation="required" label="Intensity"
                  :options="[
    { value: 0, label: 'Best Few %: One of the Mildest of 12 Months' },
    { value: 1, label: '10%: One of the Mildest' },
    { value: 2, label: '20%: Very Mild' },
    { value: 3, label: '30%: Mild' },
    { value: 4, label: '40%: Fairly Mild' },
    { value: 5, label: '50%: Middle, Average of the Last 12 Months' },
    { value: 6, label: '60%: Fairly Intense' },
    { value: 7, label: '70%: Intense' },
    { value: 8, label: '80%: Very Intense' },
    { value: 8, label: '90%: One of the Most Intense' },
    { value: 10, label: 'Worst Few%: One of the Worst of 12 Months'}
  ]"
              ></FormulateInput>
            </div>
          </div>
        </div>

        <!--        HAVING an Event  -->
        <div v-show="values.inEvent">
          <br>
          <h3>Is this the 1st time that you are reporting a newly-starting Migraine?</h3>
          <div class="buttonGroup">
            <button @click="values.startingEvent=true" class="appBtn">First Time / Just Started</button>
            <button @click="values.startingEvent=false;showIntensityRating=true;" class="appBtn">Reported It Previously</button>
          </div>

          <div v-if="values.startingEvent">
            <br>
            <h4>When did your migraine start?</h4>
            <FormulateInput v-model="values.startDate" type="date" name="date" validation="required" label=""></FormulateInput>
            <FormulateInput v-model="values.startTime" v-on:change="showIntensityRating=true" type="time" name="time" validation="required" label=""></FormulateInput>
          </div>

          <div v-show="showIntensityRating" class="numericRating">
            <br><br>
            <h4>Migraine Intensity Right Now?</h4>
            <FormulateInput v-model="values.intensity" v-on:change="done=true;" type="range" name="intensity" min="0" show-value="true" max="10" validation="required|min:0|max:10" error-behavior="live"></FormulateInput>
          </div>
        </div>
      </div>

    </div>
    <div v-show='showLinkToQx'>
      <button class='appBtn doneButton' v-on:click="QxButtonPressed">
        Please Report Full Post-Migraine Data...
      </button>
    </div>
    <div v-show='done'>
      <button class='appBtn doneButton' v-on:click="doneButtonPressed">
        Done, Submit Data
      </button>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.component = this;
  },
  data() {
    return {
      values: {
        eventType:'migraine',
        prepost:this.$root.prepost,
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        inEvent: null,
        startingEvent: null,
        endingEvent: null,
        migraine: null,
        comments: '',
        comparison: 0,
        intensity: -1,
        overallSeverity: -1,
      },
      validation: {},
      done: false,
      showIntensityRating:false,
      showLinkToQx:false,
    }
  },
  computed: {
    showDoneButton: function () {
      if (!this.answeredIfInEvent) return false;
      if (this.values.endingEvent === null) return false;
      let sd = false;
      if (this.values.inEvent) {
        if (this.values.startingEvent) {
          if ((this.values.startDate !== null) && (this.values.startTime !== null) && (this.values.intensity > -1)) {
            sd = true;
          } else {
            sd = false;
          }
        } else {
          if (this.values.intensity > -1) sd = true;
        }
      } else {
        if (this.values.endingEvent) {
          if ((this.values.endDate !== null) && (this.values.endTime !== null) && (this.values.overallSeverity > -1)) {
            sd = true;
          } else {
            if (this.values.intensity > -1) sd = true;
          }
        } else {
          sd = true;
        }
      }
      return sd;
    },
    inEvent: function(){
      return this.values.inEvent;
    },
    answeredIfInEvent: function () {
      let ret = false;
      if (this.values.inEvent === null) {
        ret = false
      } else {
        ret = true;
      }
      return ret
    },
    answeredIfEventEnding: function () {
      let ret = false;
      if (this.values.endingEvent === null) {
        ret = false
      } else {
        ret = true;
      }
      return ret
    },
    showStarting: function () {
      return (this.values.startingEvent)
    },
    showSeverity: function () {
      return this.values.inEvent;
    },
  },
  methods: {
    doneButtonPressed: function () {
      let results = updateValues(this.values);
      //let results = removeFuncFromObj(this.values);
//      let results = JSON.parse(JSON.stringify(this.values));
      window.results = results;
      console.log('Done! Results:', results);
      AppData.createEvent(results);
      this.$router.go(-1);
    },

    QxButtonPressed: function () {
      let results = updateValues(this.values);
      //let results = removeFuncFromObj(this.values);
//      let results = JSON.parse(JSON.stringify(this.values));
      window.results = results;
      console.log('Done! Results:', results);
      AppData.createEvent(results);
      let qxUrl = 'https://forms.gle/H4AdTeWs3utacaUh8'; // PostMigraineQx on google forms
      window.open(qxUrl);
    },
    clickXMethod() {
      console.log('clicked');
      this.$router.go(-1);
      //add code that you wish to happen on click
    },
  }
}

function updateValues(values) {
  // strip off all the functions
  let results = {};
  Object.keys(values).map((key) => {
    console.log(values[key]);
    results[key] = values[key]
  });

  let dateStr;
  if (results.startDate !== null) {
    dateStr = results.startDate + " " + results.startTime;
    //console.log(dateStr);
    results.startDateValue = new Date(dateStr);
  }
  if (results.endDate !== null) {
    dateStr = results.endDate + " " + results.endTime;
    results.endDateValue = new Date(dateStr);
  }
  if (results.comparison !== null) results.comparison = parseInt(results.comparison);
  if (results.intensity !== null) results.intensity = parseInt(results.intensity);
  return results;
}
</script>


<style lang="scss" scoped>
@import './formstyle/snow.scss';

select {
  font-size: 200% !important;
}

h1 {
  text-align: center;
  color: whitesmoke;
  width: 100%;
  font-size: 1.2em;
  padding: 0 !important;
  margin: 0 !important;
}

h3 {
  text-align: center;
  color: whitesmoke;
  width: 100%;
  font-size: 1em;
  padding: 0 !important;
  margin: 0 !important;
}

h4 {
  margin: 0%;
  text-align: center;
  color: whitesmoke;
  text-transform: uppercase;
  font-size: .8em;
  opacity: .7;
}

p {
  color: white;
  text-align: center;
  margin: 5%;
}

div {
  width: 100%;
  text-align: center;
}
.outerContainer {
}
.container {
  color: white;
  width: 33vw;
  height: 33vh;
  margin: 0 auto;
//  background-color: navy;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  transform: scale(3) translate(0, 33%);
}

.currentlyHavingMigraine {
  height: 20%;
  top: 0;
  position: absolute;
}

.eventReporting {
  height: 93%;
  width: 90%;
  margin: 0%;
  position: absolute;
  top: 7%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.numericRating {
  width: 100%;
}

.getUserInputsForm {
  height: 30%;
  margin: 10%;
  position: absolute;
  top: 20%;
  text-align: center;
}

.centerToggle {
  display: flex;
  justify-content: center;
}

.buttonGroup {
  display: flex;
  justify-content: center;

}

.doneButton {
  position: absolute;
  bottom: 6%;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  padding: 3%;
  width:60% !important;
}

.appBtn {
  text-decoration: none;
  color: #fff;
  background-color: #4285f4;
  text-align: center;
  letter-spacing: .5px;
  width: 30%;
  padding: 1.5%;
  margin: 1%;
  border: none;
}
.closeX {
  position: absolute;
  top: 1%;
  right: -45%;
  font-size: 1em;
  color:white;
  z-index: 1000;
}
</style>