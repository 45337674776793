require("../AppFirebase/AppFirebase");
require( "../AppData/AppReadableId");
require("./AppConsole");
const get = require('lodash/get')

AppData =
{
    initialized: false,
    data: {
        appUser: {
            email:'testEmail',
            firstName:'testName1',
            numSessions: 0,
            focusScoreHigh: 0,
            breathScoreHigh: 0,
            characterNumber: 0,
        },
        session:{},
        breathTraces:[],
        breathTracesMean:[],
        blankSession: {
            status:'',

            startTime:0,
            endTime: 0,
            duration: 0,

            focusScoreArray:[0],
            focusScoreTotal: 0,
            focusScoreMedian: 0,
            focusNumHits:0,
            focusNumErrors:0,

            breathScoreArray:[0],
            breathScoreTotal: 0,
            breathScoreMedian: 0,

            heartScoreArray:[0],
            heartScoreTotal: 0,
            heartScoreMedian: 0,

            breathingRate:0,

            breathTraces: [],
            averageBreathTrace:[],

            nextPractice: {
                practiceNum: 1,
                practiceName: 'Testful',
                durationMins: 7.1
            },

            practiceComplete: false,
        },
        testuser: {
            email:'testEmail',
            firstName:'testName'
        }
    },
    logit: true,
    sessionActive: false,

    delayBeforeStartSession: 1 * 1000, // msec delay before considering appSession started
    sessionUpdateDelay: 30 * 1000, // msec delay before updating appSession time
    endDelay: 40 * 60 * 1000, // msec delay before ending appSession time if it isn't ended some other way
    startTime: 0,
    turnOffSessionMonitoring: false,
    interval: null,
    delayBeforeQCCheck: 20 * 1000,
    session: {},

    init: function() {
        if (this.initialized) return;
        this.initialized = true;
    },
    createSession: async function  () {
        if (!this.initialized) this.init();
        if (this.logit) AppConsole.log('Creating Session in AppData');
        this.startTime = new Date();
        this.data.appSession = {... this.data.blankSession};
        this.data.appSession.id = AppReadableId();
        this.data.appSession.duration= 0;
        this.data.appSession.email = this.data.appUser.email;
        this.data.appSession.userId = this.data.appUser.userId;
        let nextPractice = this.data.appUser.nextPractice;
        if (nextPractice)
            this.data.appSession.nextPractice = nextPractice;
        this.data.appSession.sessionNum = this.incrementSessionNumber();
        if (this.logit) AppConsole.log('AppDate Creating New Session, Writing to dB:',this.data.appSession);
        await AppFirebase.dbCreateSession(this.data.appSession);
        if (this.logit) AppConsole.log('New session written to db');
    },
    createEvent: async function  (appEvent) {
        if (!this.initialized) this.init();
        if (this.logit) AppConsole.log('Creating Event in AppData');
        let appUser = AppFirebase.appUser;
        appEvent.id = AppReadableId();
        appEvent.email = get(appUser,'email','');
        appEvent.firstName = get(appUser,'firstName','');
        appEvent.lastName = get(appUser,'lastName','');
        appEvent.ui = get(appUser,'ui','');
        appEvent.providerUid = get(appUser,'providerUid','');
        appEvent.displayName = get(appUser,'displayName','');
        if (this.logit) AppConsole.log('AppDate Creating New Session, Writing to dB:',appEvent);
        await AppFirebase.dbCreateEvent(appEvent);
        if (this.logit) AppConsole.log('New session written to db');
    },
    startSession: function() {
        if (!this.initialized) return;

        if (this.logit) console.log('Starting Session');

        this.sessionActive = true;
        AppData.data.appSession.startTime = new Date();

        // update appSession duration into db at regular interval
        this.sessionInterval = setInterval(() =>
            AppData.updateSession(), this.sessionUpdateDelay);

        // end of appSession
        setTimeout(() => {
            AppData.endSession()
        }, this.endDelay);
    },

    endSession: function() {
        clearInterval(this.sessionInterval);
        this.sessionActive = false;
    },

    incrementSessionNumber: function() {
        return;
        let sessionNum = 0;
        if (sessionNum > 0) {
            sessionNum = this.data.appUser.numSessions
            AppData.set('appUser', 'numSessions',sessionNum);
            this.data.appUser.numSessions += 1;
            if (this.logit) AppConsole.log('Incrementing appUser appSession number for appUser:',this.user);
        }
        else {
            sessionNum = 1;
            this.data.appUser.numSessions = 1;
        }
        if (this.logit) AppConsole.log('Increment Session Num Result = ',sessionNum);
        return sessionNum
    },

    updateSession: function() {
        if (!this.initialized) return;
        if (!this.sessionActive) {
            clearInterval(this.sessionInterval);
            return
        }
        if (this.logit) console.log('Updating Session',this.data.appSession)
        var dt = new Date()
        var duration = Math.round((dt - AppData.data.appSession.startTime) / 1000)
        var evenDuration = (Math.round(duration / (AppData.sessionUpdateDelay/1000))) * (AppData.sessionUpdateDelay/1000); // round to common lengths
        console.log('Duration: ',duration,' Even Duration:',evenDuration);
        this.data.appSession.duration = evenDuration;
        console.log('Going to write appSession to db...',this.data.appSession)
        AppFirebase.dbUpdateSession(this.data.appSession)
    },

    median: function(data) {
        var len = data.length;
        if (len === 0) return 0; // null?
        let dataSorted = data.concat().sort();
        let mid = Math.floor(len/2);
        let result = dataSorted[mid];
//        console.log('Sorted:',dataSorted);
//        console.log('mid:',mid);
        return result;
    },

    addFocusScore: function (value) {
        value = Math.round((value * 100)/100)
        try {
        this.data.appSession.focusScoreArray.push(value);
        this.data.appSession.focusScoreTotal += value;
        this.data.appSession.focusScoreMedian = this.median(this.data.appSession.focusScoreArray);
        this.data.appSession.focusNumHits++;
        }
        catch (e) {console.log('Error adding Focus Score: ',e)}
    },

    addFocusScoreError : function() {
        this.data.appSession.focusNumErrors++;
    },

    addBreathScore: function (value) {
        //console.log('Adding Breath Score:',value)
        value = Math.round((value * 100)/100)
        try{
        this.data.appSession.breathScoreArray.push(value);
        this.data.appSession.breathScoreTotal += value;
        this.data.appSession.breathScoreMedian = this.median(this.data.appSession.breathScoreArray);
        }
        catch(e){console.log('Error adding breath score: ',e)}
    },

    addHeartcore : function(value) {
        value = Math.round((value * 100)/100)
        this.data.appSession.heartScoreArray.push(value);
        this.data.appSession.heartScoreTotal += value;
        this.data.appSession.heartScoreMedian = this.median(this.data.appSession.heartScoreArray);
    },

    addBreathTrace : function(trace) {
        this.data.breathTraces.push(trace);
    },

    updateBreathTracesMean (trace) {
        this.data.breathTracesMean = trace; // copy passed array
    },

    // TODO R1: add write to AppFirebase function for appUser updates
    // Related to AppDatabase, Update
    updateUser: function(next) {
        this.data.appUser = next;
        this.updateGlobalUser();
    },

    getSessionInfo: function() {
        return this.data.appSession;
    },
}
